import React from 'react';
import './index.css'; // Import the CSS file for styling
import whatsappImage from '../../assets/img/whatsapp-48.svg'
// https://wa.me/message/Y7Q43TDSU427O1

const WhatsAppChatWidget = () => {
//   const openWhatsAppChat = () => {
//     const url = "https://wa.me/message/Y7Q43TDSU427O1";
//     // const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
//     window.open(url, '_blank');
//   };

  return (
    <div className="whatsapp-chat-widget">
        <a href="https://wa.me/message/Y7Q43TDSU427O1" className="whatsapp-button" target="_blank" rel="noreferrer">
            <img src={whatsappImage} alt='whatsapp' />
        </a>
      {/* <button onClick={openWhatsAppChat} className="whatsapp-button">
      </button> */}
    </div>
  );
};

export default WhatsAppChatWidget;

import React, { Fragment, useState, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMacOs, isIOS } from "react-device-detect";
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import trLogo from "../../../assets/img/tradefadaLogo.svg";
// import tradefadaLogo from "../../../assets/img/tradefada-logo-white.svg";
import bars from "../../../assets/img/bars.svg";
// import coinPalm from "../../../assets/img/coinPalm.svg";
import redcoin from "../../../assets/img/redcoin.svg";
import redapple from "../../../assets/img/redapple.svg";
import signal from "../../../assets/img/signal.svg";
import redplaystore from "../../../assets/img/redplaystore.svg";
import "./Header.css";
import CustomSelectProps from "../../../common/CustomSelect/CustomSelect";
import { Link } from "react-router-dom";
import { HeaderMenuButton } from "./HeaderMenuButton";
import WidgetModal from "../../buySellWidget/WidgetModal";

import AdBar from "./AdBar";

const renderLoader = () => (
  <UseAnimations
    className="mx-auto"
    style={{ marginTop: "5px", marginBottom: "5px" }}
    animation={loading}
    size={100}
    strokeColor={"#af0202"}
  />
);
const NotAvailableModal = lazy(() =>
  import("../../../pages/notAvailablePage/NotAvailableContainer")
);

export const BlockNavMenu = () => {
  const [showModal, setShowModal] = useState(false);

  const [iframeloading, setIframeLoading] = useState(true);
  const [showWidget, setShowWidget] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleIframe = () => {
    setShowWidget(true);
    setIframeLoading(false);
  };
  const handleCloseWidget = () => {
    setShowWidget(false);
  };

  return (
    <div className="container">
      <div className=" col-nav align-items-center ">
        {" "}
        <ul className="">
          <li className="d-block d-sm-block  d-md-none">
            <a
              className="text-gray fnt-12 font-medium "
              href="https://exchange.tradefada.com/signinapp"
              rel="noopener noreferrer"
            >
              Spot Exchange
            </a>
          </li>
          <li className="d-block d-sm-block  d-md-none " onClick={handleIframe}>
            <div
              className="text-gray fnt-12 unavaliable-links "
              // href="https://trade.tradefada.com/buy-sell"
              rel="noopener noreferrer"
            >
              Instant Buy/Sell (NGN)
            </div>
          </li>
          <li
            className="d-block d-sm-block  d-md-none "
            onClick={handleOpenModal}
          >
            <div
              className="text-gray fnt-12 unavaliable-links "
              rel="noopener noreferrer"
            >
              Borrow
            </div>
          </li>

          <li>
            <div
              className="text-gray fnt-12 font-medium unavaliable-links"
              rel="noopener noreferrer"
              onClick={handleIframe}
            >
              Tradefada Direct
            </div>
          </li>
          <li className="d-block d-sm-block  d-md-none ">
            <a
              className="text-gray fnt-12 font-demiBold"
              href="https://exchange.tradefada.com/signinapp"
              rel="noopener noreferrer"
            >
              Login
            </a>
          </li>
          <li className="d-block d-sm-block  d-md-none text-darkred fnt-12 ">
            <a
              href="https://exchange.tradefada.com/signupapp"
              rel="noopener noreferrer"
            >
              {" "}
              <div
                className="fnt-12 text-darkred font-demiBold"
                style={{ color: "#af0202" }}
              >
                Create Account{" "}
              </div>
            </a>
          </li>
          <li className="d-block d-sm-block  d-md-none text-darkred fnt-12 ">
            <Link to="/partner" rel="noopener noreferrer">
              <div
                className="fnt-12 text-darkred font-demiBold"
                style={{ color: "#af0202" }}
              >
                Become Partner{" "}
              </div>
            </Link>{" "}
          </li>
          <li>
            <a
              className="text-gray fnt-12 font-medium"
              href="https://exchange.tradefada.com/about-us"
              rel="noopener noreferrer"
            >
              About Us
            </a>
          </li>
          <li onClick={handleOpenModal}>
            <div
              className="text-gray fnt-12 font-medium unavaliable-links"
              href="#"
              rel="noopener noreferrer"
            >
              Contact Us
            </div>
          </li>
        </ul>
        <Suspense fallback={renderLoader()}>
          <NotAvailableModal show={showModal} handleClose={handleCloseModal} />
        </Suspense>
        <Suspense>
          <WidgetModal
            showModal={showWidget}
            handleClose={handleCloseWidget}
            iframeloading={iframeloading}
          />
        </Suspense>
      </div>
    </div>
  );
};
export const NavBarContent = () => {
  const location = useLocation();
  const [showtoggle, setShowToggle] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const handleOpenModal = () => {
  //   setShowModal(true);
  // };

  const displayMenu = () => {
    setShowToggle(false);
    setShowMenu(true);
  };
  const hideMenu = () => {
    setShowToggle(true);
    setShowMenu(false);
  };
  const countryTypeOptions = [{ value: "English", label: "EN" }];
  return (
    <div className="bg-white">
      <div className="container">
        <div className="d-flex  align-items-center justify-content-between nav bg-white">
          <div className="d-flex ">
            <Link to="/">
              <img
                src={trLogo}
                alt="logo"
                width="112.583px"
                height="23.809px"
              />
              {/* <img src={squares} className="ml-3 mr-4"  alt="tf" width="21px" height="21px"/> */}
            </Link>
            {location.pathname === "/partner" && (
              <div className="align-items-center partnerLogo">Partnership</div>
            )}
          </div>

          <div className=" nav-div-ul   align-self-center ml-auto">
            <ul>
              <li className=" active  text-darkred fnt-12">
                <CustomSelectProps
                  defaultValue={countryTypeOptions[0]}
                  options={countryTypeOptions}
                  controlWidth="50px"
                  containermargin="-5px 0p"
                  indicatorPadding="0px 0px !important"
                  primaryColor="#f2f2f2"
                  secondaryColor="#AF0202"
                  minWidth="40px"
                  label
                />
              </li>
              <li className="">
                <a
                  className="text-gray fnt-12 font-demiBold"
                  href="https://exchange.tradefada.com/signinapp"
                  rel="noopener noreferrer"
                >
                  Login
                </a>
              </li>
              <li className=" text-darkred fnt-12 ">
                <a
                  href="https://exchange.tradefada.com/signupapp"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <div
                    className="create-account-btn   text-darkred font-demiBold"
                    style={{ color: "#af0202" }}
                  >
                    Create Account{" "}
                  </div>
                </a>
              </li>
              <li className=" text-darkred fnt-12 ">
                <Link to="/partner" rel="noopener noreferrer">
                  {" "}
                  <div
                    className="create-account-btn   text-darkred font-demiBold"
                    style={{ color: "#af0202" }}
                  >
                    Become Partner{" "}
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex-shrink-1 align-self-center toggle-div ">
            {" "}
            <input type="checkbox" id="res-menu" />
            <label htmlFor="res-menu">
              {showtoggle && FontAwesomeIcon && (
                <div onClick={displayMenu}>
                  <FontAwesomeIcon icon={faBars} className=" visible-toggle" />
                </div>
              )}
              {!showtoggle && (
                <div onClick={hideMenu}>
                  {" "}
                  <FontAwesomeIcon icon={faTimes} className=" visible-toggle" />
                </div>
              )}
            </label>
            {showMenu && <BlockNavMenu />}
            <Suspense fallback={renderLoader()}>
              <NotAvailableModal
                show={showModal}
                handleClose={handleCloseModal}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};
export const HeaderMenuButtons = () => {
  let downloadLink;
  if (isIOS || isMacOs) {
    downloadLink =
      "https://apps.apple.com/ng/app/tradefada-global/id1540975439";
  } else {
    downloadLink =
      "https://play.google.com/store/apps/details?id=tradefada.com.exchange.app";
  }
  const menuButtons = [
    {
      id: 1,
      img: redcoin,
      boldText: "Instant Exchange",
      subText: "Buy and Sell",
      showIframeModal: true,
      height: "38",
      width: "38",
    },
    {
      id: 2,
      img: bars,
      boldText: "Spot Exchange",
      subText: "Trade your crypto here",
      linkRoute: "https://exchange.tradefada.com/signinapp",
      height: "48",
      width: "41",
    },

    // {
    //   id: 3,
    //   img: coinPalm,
    //   boldText: "Borrow",
    //   subText: "Take a crypto loan",
    //   linkRoute: "#",
    //   height: "33",
    //   width: "50",
    // },
    {
      id: 4,
      img: signal,
      boldText: "Token/Signals",
      subText: "See the highs and lows",
      locationRoute: "/analytics",
      height: "21",
      width: "35",
    },
    {
      id: 5,
      img: redapple,
      boldText: "Download App",
      linkRoute: downloadLink,
      additionalImg: redplaystore,
      height: "30",
      width: "35",
      additionalImgwidth: "28",
      additionalImgheight: "30",
    },
  ];
  return (
    <div className=" ">
      <div className="container ">
        <div className="d-flex justify-content-between flex-wrap flex-lg-nowrap ">
          {menuButtons.map((i) => (
            <HeaderMenuButton
              key={i.id}
              img={i.img}
              boldText={i.boldText}
              subText={i.subText}
              linkRoute={i.linkRoute}
              locationRoute={i.locationRoute}
              additionalImg={i.additionalImg}
              showIframeModal={i.showIframeModal}
              height={i.height}
              width={i.width}
              additionalImgwidth={i.additionalImgwidth}
              additionalImgheight={i.additionalImgheight}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export const Header = () => {
  // const location = useLocation();

  return (
    <Fragment>
      {/* <div className="d-flex align-items-center justify-content-center top-bg alt-top-bar-color  ">
        <div className="d-md-flex align-items-center justify-content-center ">
          <img
            src={tradefadaLogo}
            width="100px"
            height="100%"
            className="logoImage"
            alt="logo"
          />
          <div className="vertical-line"></div>
          <span className="text-white tag-line">GLOBAL</span>
          <div className="text-white top-text">
            <span className="font-bold">Professional trading</span> with our new
            exchange platform
          </div>
        </div>

        <a
          className="top-button text-darkred text-center oval-border"
          href="https://exchange.tradefada.com/signinapp"
          rel="noopener noreferrer"
        >
          Trade GLOBAL
        </a>
      </div> */}

      <AdBar />
      <NavBarContent />
      {/* {location.pathname !== "/partner" && <HeaderMenuButtons />} */}
    </Fragment>
  );
};

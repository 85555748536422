import {
  FETCH_YOUTUBE_VIDEOS,
  FETCHING_YOUTUBE_VIDEOS,
} from "../actions/types";

const initialState = {
  youtubeVideos: [],
  nextPageToken: null,
  pageInfo: { resultsPerPage: 0, totalResults: 0 },
  isLoading: false,
};

const videosReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_YOUTUBE_VIDEOS:
      return {
        ...state,

        youtubeVideos: state.youtubeVideos.concat(payload.items),

        nextPageToken: payload.nextPageToken ? payload.nextPageToken : null,
        pageInfo: {
          resultsPerPage: payload.pageInfo.resultsPerPage,
          totalResults: payload.pageInfo.totalResults,
        },

        isLoading: false,
      };
    case FETCHING_YOUTUBE_VIDEOS:
      return { ...state, isLoading: true };
    default:
      return state;
  }
};

export default videosReducer;

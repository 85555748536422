import React, { Fragment } from "react";
import LazyLoad from "react-lazy-load";
import "./ProductMarketCard.css";

export const ProductMarketCard = ({
  title,
  text,
  bgColor,
  bgImage,
  className,
  href,
  onClick,
  imageWidth = "100%",
  imageHeight = "100%",
}) => {
  return (
    <Fragment>
      {onClick && (
        <div
          className={`productCard pt-5    no-border  ${className}`}
          style={{ backgroundColor: `${bgColor}` }}
          onClick={onClick ? onClick : href}
          href={href ? href : null}
        >
          <p className="productCardheader font-demiBold">{title}</p>
          <div className="d-none d-sm-none d-lg-block productCardImage ">
            <div className="element-to-stick-to-bottom">
              <LazyLoad height={imageHeight} width={imageWidth}>
                <img
                  src={bgImage}
                  alt="uno images"
                  width={imageWidth}
                  height={imageHeight}
                />
              </LazyLoad>
            </div>
          </div>

          <p className="mr-auto  productCardText font-medium">{text}</p>
        </div>
      )}

      {href && (
        <div
          className={`productCard  pt-5    no-border ${className}`}
          style={{ backgroundColor: `${bgColor}` }}
        >
          <a
            href={href}
            rel="noopener noreferrer"
            // style={{ backgroundColor: `${bgColor}` }}
          >
            <p className="productCardheader font-demiBold">{title}</p>
            <div className="d-none d-sm-none d-lg-block productCardImage ">
              <div className="element-to-stick-to-bottom">
                <LazyLoad height={imageHeight} width={imageWidth}>
                  <img
                    src={bgImage}
                    alt="uno images"
                    width={imageWidth}
                    height={imageHeight}
                  />
                </LazyLoad>
              </div>
            </div>

            <p className="mr-auto  productCardText font-medium">{text}</p>
          </a>{" "}
        </div>
      )}
    </Fragment>
  );
};



import React, { Fragment } from 'react';
import Select, { components } from 'react-select';





const customStyles = {
   container: (provided,state) => ({
      ...provided,
      top:state.selectProps.containertop,
      left:state.selectProps.containerleft,
      margin: state.selectProps.containermargin,
    border: "none",
    
      
    }),
  option: (provided, state, ) => ({
    ...provided,
  
     color: state.selectProps.secondaryColor,
    backgroundColor:state.selectProps.primaryColor,
   
    "&:hover": {
        backgroundColor: state.selectProps.secondaryColor,
        color:  state.selectProps.primaryColor,
    },
  
  }),
   
  control: (provided,state) => ({
     ...provided,
     color:state.selectProps.secondaryColor,
    backgroundColor: state.selectProps.primaryColor,
       minWidth:state.selectProps.minWidth?state.selectProps.minWidth:"100px",
    width: state.selectProps.controlWidth?state.selectProps.controlWidth:"100px",
    border: "none",
       fontFamily:state.selectProps.fontFamily,
      fontWeight:state.selectProps.fontWeight?state.selectProps.fontWeight:"normal",
      fontSize:state.selectProps.fontSize?state.selectProps.fontSize:"12px",
    height:state.selectProps.controlHeight,
      "&:active": {
        border: "none",
      
    },
    
  }),
  
 input: ( { selectProps:  secondaryColor }) => ({
    
      color: { secondaryColor },
      textTransform : "uppercase",
  '& input': {
    font: 'inherit',
  },
}),

     menuList: (provided, )  => ({
      ...provided,
       maxHeight: "100px",
      
       
  }),
  
     menu: (provided,state )  => ({
      ...provided,
       color: state.selectProps.secondaryColor,
      fontFamily:state.selectProps.fontFamily,
      backgroundColor:state.selectProps.primaryColor,
      fontWeight:state.selectProps.fontWeight?state.selectProps.fontWeight:"normal",
      fontSize:state.selectProps.fontSize?state.selectProps.fontSize:"12px",
       marginTop: "4px",
      border:"none",
      "&:active": {
        border:"none"
        
       },
    }),
   clearIndicator: (provided, state) => ({
    ...provided,
       color: state.selectProps.secondaryColor,
   
  }),
         singleValue: (provided, state) => ({
    ...provided,
      color: state.selectProps.secondaryColor,
      
   
  }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.selectProps.secondaryColor,
      padding:state.selectProps.indicatorPadding
     }),
      valueContainer:(provided, state) => ({
      ...provided,
   
       padding: state.selectProps.valueContainerPadding?state.selectProps.valueContainerPadding:"2px 8px",
    }),
}





  
const IndicatorSeparator = ({ innerProps }) => {
  return null;
};
  const Option = ({data,...props}) => (
    <components.Option {...props}>
      
     {data.icon && data.label ? (
        <Fragment>
          <img
            src={data.icon}
            style={{ width: "20px" , height:"20px"}}
            alt={data.label}
          />{" "}
          <span>{data.optionValue?data.optionValue:data.label}</span>
        </Fragment>
      ) : data.icon ? (
        <Fragment>
          <img
            className={`icon icon-${data.icon}`}
            src={data.icon}
            style={{ width: "20px" , height:"20px" }}
            alt={data.label}
          />
        </Fragment>
      ) : (
       data.optionValue?data.optionValue:data.label
      )}
     
    </components.Option>
  );

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}> {data.icon && data.label ? (
          <Fragment>
            {" "}
            <img
              // className="input-select__icon"
              
              src={data.icon}
              style={{ width: "20px" , height:"20px" }}
              alt={data.label}
            />{" "}
            {data.label}
          </Fragment>
        ) : data.icon ? (
          <Fragment>
            {" "}
            <img
              // className="input-select__icon"
              src={data.icon}
              style={{ width: "20px" , height:"20px"}}
              alt={data.label}
            />
          </Fragment>
        ) : (
          data.label
        )}</components.SingleValue>
);

  
const CustomSelectProps = props => {
  const {defaultValue,minWidth, options,fontWeight,primaryColor,secondaryColor,indicatorPadding,fontFamily,controlData, controlWidth,controlHeight,containermargin,optionImages,fontSize,valueContainerPadding,containertop,containerleft, onChange}=props
 
 

  return (
    <Select
      {...props}
     defaultValue={defaultValue}
    options={options}
      styles={customStyles}
      fontWeight={fontWeight}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      optionImages={optionImages}
      controlWidth={controlWidth}
      controlHeight={controlHeight}
      valueContainerPadding={valueContainerPadding}
      containertop={containertop}
      containerleft={containerleft}
      containermargin={containermargin}
      fontSize={fontSize}
      fontFamily={fontFamily}
      indicatorPadding={indicatorPadding}
      minWidth={minWidth}
      controlData={controlData}
      components={{ IndicatorSeparator,SingleValue ,Option}}
      onChange={onChange}
     
    />
  );
};

export default CustomSelectProps;
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft,faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import userImage from "../../assets/img/woman.png";
import instagramImage from "../../assets/img/Instagram-logo.png";


export const TestimonyCarosels = () => {
  const arrowStyles={
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 25,
    height: 25,
    cursor: 'pointer',
};

const indicatorStyles=  {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
  };
 
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showIndicators={false}
      showStatus={false}
      className=" container "
      showThumbs={false}


      renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
               <FontAwesomeIcon icon={faChevronLeft} className="fnt-18 d-none d-sm-none d-md-block"   onClick={onClickHandler}
            style={{ ...arrowStyles, left: 0, color: "#696969" }}/>
        //   <i className="fa fa-chevron-left fnt-18 d-none d-sm-none d-md-block"
        //      onClick={onClickHandler}
        //     style={{ ...arrowStyles, left: 0, color: "#696969" }}></i>         
        )
    }
    renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
             <FontAwesomeIcon icon={faChevronRight} className="fnt-18 text-gray d-none d-sm-none d-md-block"  onClick={onClickHandler}
          style={{ ...arrowStyles, right: 0, color: "#696969" }}/>
        // <i className="fa fa-chevron-right fnt-18 text-gray d-none d-sm-none d-md-block"
        //    onClick={onClickHandler}
        //   style={{ ...arrowStyles, right: 0, color: "#696969" }}></i>          
        )
    }
    renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
            return (
                <li
                    style={{ ...indicatorStyles, background: '#000' }}
                    // aria-label={`Selected: ${label} ${index + 1}`}
                    // title={`Selected: ${label} ${index + 1}`}
                />
            );
        }
        return (
            <li
                style={indicatorStyles}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                 title={`${label} ${index + 1}`}
                aria-label={`${label} ${index + 1}`}
            />
        );
    }}
    >
      <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred mb-2 font-demiBold">
            Partnering with the best.
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “Tradefada always the best.”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                className="rounded-circle "
                src={userImage}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">
              
Oruenbors <br />
              <span className="fnt-19 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
      <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred font-demiBold">
           What we offer customers
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “Too Reliable I’m proud of you.”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                 className="rounded-circle "
                src={instagramImage}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">e_courage <br />
              <span className="fnt-18 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
    <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred font-demiBold">
           We keep our customers excited!
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “Y’all are amazing. So reliable that I can’t even look for somewhere else. Keep the energy up”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                 className="rounded-circle "
                src={instagramImage}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">dejay_tee<br />
              <span className="fnt-18 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
          <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred font-demiBold">
            Real people. Real cash. Real difference.
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “Tradefada 101% legit, first time I was scared to trade Bt now , am happy i found their platform . God bless U guyz”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                 className="rounded-circle "
                src={instagramImage}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">sinomaklive<br />
              <span className="fnt-18 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
       <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred font-demiBold">
           What we offer customers
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “I recommend tradefada for all crypto traders. Really fast payment for real.”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                className="rounded-circle "
                src={instagramImage}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">chrisypog<br />
              <span className="fnt-18 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
           <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred font-demiBold">
          Real people. Real cash. Real difference.
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “Nice trading with you guy on Sunday... Instant alert....”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                 className="rounded-circle "
                src={instagramImage}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">kechukwuj3<br />
              <span className="fnt-18 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
           <div className="slider-container-white ">
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container ">
          <p className="adHeader text-darkred font-demiBold">
          Real people. Real cash. Real difference.
          </p>
          <div className=" d-flex align-items-center justify-content-center pt-4">
            <p className="testimonyText cText text-gray font-regular">
              “Best trading platform 🔥🔥🔥🔥,”
            </p>
          </div>

          <div className="d-flex  align-items-center justify-content-center mt-3 ">
            <div className="caroselmage">
              <img
                className="bd-placeholder-img rounded-circle "
                src={instagramImage}
                alt="user testimony"
                width="100%"
                height="100%"
               
              />
            </div>

            <p className="line-height-20 fnt-22 text-darkred mt-2 font-demiBold">slmbnks7<br />
              <span className="fnt-18 text-gray font-italic">Instagram</span>
            </p>
          </div>
        </div>
      </div>
    </Carousel>
  );
};



import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./ModalComponent.css";

export const ModalComponent = ({
  modalTitle,
  children,
  footer,
  size,
  actionButton,
  actionButtonTitle,
  show,
  backdrop,
  handleClose, dialogClassName, contentClassName
}) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size={size}
      animation={false}
      backdrop={backdrop}
      dialogClassName={dialogClassName}
      contentClassName={contentClassName}
    >
      <Modal.Header closeButton>
        {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
      </Modal.Header>
      <Modal.Body scrollable="true">{children}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {actionButton ? (
            <Button variant="primary" onClick={actionButton}>
              {actionButtonTitle}
            </Button>
          ) : null}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalComponent;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useVideos } from "../../redux/selectors";
import { getYoutubeVideos } from "../../redux/actions/videosActions";
import isEmpty from "../../helpers/is-empty";
import produce from "immer";
import "./VideoSection.css";
import VideoModal from "./videoModal/VideoModal";
import Spinner from "../../helpers/Spinner/Spinner";

export const VideoGallery = () => {
  const dispatch = useDispatch();
  const videoState = useVideos();
  const isLoading = videoState.isLoading;

  const [youtubeVideos, setYoutubeVideos] = useState(null);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [filter, setFilter] = useState({
    maxResults: 6,
    pageToken: null,
  });
  // useEffect(() => {
  //   dispatch(getYoutubeVideos(data));
  // }, [getYoutubeVideos]);
  useEffect(() => {
    fetchVideos(filter);

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  const fetchVideos = (filter) => {
    dispatch(getYoutubeVideos(filter));
  };

  useEffect(() => {
    if (videoState.youtubeVideos && videoState.youtubeVideos.length > 0) {
      setYoutubeVideos(videoState.youtubeVideos);
    }
    if (videoState.nextPageToken !== null) {
      setNextPageToken(videoState.nextPageToken);
    } else {
      setNextPageToken(null);
    }
  }, [videoState.youtubeVideos, videoState.nextPageToken]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = (id) => {
    handleSelectedVideo(id);
    setShowModal(true);
  };
  const handleSelectedVideo = (id) => {
    const result = youtubeVideos.filter((item) => item.id === id);
    setSelectedVideo(result);
  };

  const onloadMoreVideos = () => {
    setFilter(
      produce((draft) => {
        draft.pageToken = nextPageToken;
      })
    );
  };
  return (
    <Fragment>
      <div className="  pt-1 pt-md-4 mb-3  mb-md-4 px-xs-2 px-md-2">
        <p className="videogallerysectionheader font-bold">How to videos</p>
        {isLoading && (
          <div className="d-flex justify-content-center my-5">
            <Spinner className="fnt-w-800 fnt-50 " />
          </div>
        )}
        <div className="row text-center mb-3 videoContainer">
          {!isLoading &&
            youtubeVideos &&
            youtubeVideos !== null &&
            youtubeVideos
              .filter((data) => !isEmpty(data.snippet.thumbnails.medium))
              .map((item) => {
                const { id, snippet = {} } = item;
                const { title, thumbnails = {} } = snippet;
                const { medium = {} } = thumbnails;
             
                return (
                  <div key={id ? id : null} className="col-12 col-md-4 mt-4 ">
                    <div className="videocards ">
                      {" "}
                      <img
                        width="100%"
                        height="100%"
                        src={medium.url}
                        className="videocardsimg"
                        alt={title}
                      />
                      <div
                        className="videocardsOverlay"
                        onClick={medium.url ? () => handleOpenModal(id) : null}
                      ></div>
                    </div>

                    <div className="videogallerytext mt-1">
                      {medium.url ? title : "Video Unavailable"}
                    </div>
                  </div>
                );
              })}
        </div>
        {nextPageToken !== null && (
          <div className="text-center">
            <button
              className="videoSeemoreButton no-border no-outline text-center"
              onClick={onloadMoreVideos}
            >
              <span className="mr-3 font-bold">MORE VIDEOS</span>
              <FontAwesomeIcon icon={faPlay} className="ml-xs-1 ml-md-2" />
            </button>
          </div>
        )}
        <div className="container text-white ">
          {" "}
          <VideoModal
            handleClose={handleCloseModal}
            video={selectedVideo}
            showModal={showModal}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default VideoGallery;

import React from "react";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import yahooImage from "../../assets/img/yahoofinance.svg";
import cryptotv from "../../assets/img/ctvplus.svg";
import cointelegraph from "../../assets/img/cointelegraph.svg";
import guardianLogo from "../../assets/img/guardian_logo.svg";
import dailytimesLogo from "../../assets/img/dailytimes_logo.svg";
import vanguard from "../../assets/img/vanguardlogo.svg";

import thisDay from "../../assets/img/thisDay.svg";
import marketwatch from "../../assets/img/marketwatch.svg";

export const MediaCarosels = () => {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  const indicatorStyles = {
    background: "#fff",
    width: 8,
    height: 8,
    display: "inline-block",
    margin: "0 8px",
  };
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showIndicators={true}
      showStatus={false}
      className="container"
      showThumbs={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="fnt-18 d-none d-sm-none d-md-block"
            onClick={onClickHandler}
            style={{ ...arrowStyles, left: 0, color: "#696969" }}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="fnt-18 text-gray d-none d-sm-none d-md-block"
            onClick={onClickHandler}
            style={{ ...arrowStyles, right: 0, color: "#696969" }}
          />
        )
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <li
              style={{ ...indicatorStyles, background: "#000" }}
              // aria-label={`Selected: ${label} ${index + 1}`}
              // title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <li
            style={indicatorStyles}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      <div className="slider-container-gray">
        <a
          href="https://uk.finance.yahoo.com/quote/UNO-BTC/"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={yahooImage}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText font-regular"></p>
            </div>
          </div>
        </a>
      </div>
      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://cointelegraph.com/news/altcoin-digest-10-coins-that-cant-be-neglected-op-ed"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={cointelegraph}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>

      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://cryptotvplus.com/2019/03/broda-shaggi-brand-ambassador-for-nigeria-blockchain-company/"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={cryptotv}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>

      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://cointelegraph.com/tags/unobtanium"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={cointelegraph}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>

      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://dailytimes.ng/digital-currency-platform-unveils-brodashaggi-brand-ambassador/"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={dailytimesLogo}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>

      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://guardian.ng/business-services/new-digital-currency-trading-app-unveiled/"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={guardianLogo}
                  alt="user testimony"
                  width="100%"
                  height="60%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>
      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://www.vanguardngr.com/2021/05/new-mobile-app-for-instant-cryptocurrency-exchange-debuts-in-lagos/"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={vanguard}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>
      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <a
          href="https://www.thisdaylive.com/index.php/2021/05/11/new-digital-currency-exchange-unveiled-in-lagos/"
          rel="noopener noreferrer"
        >
          <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
            <div className=" d-flex align-items-center justify-content-center pt-5 ">
              <div className="clientCarousellmage">
                <img
                  src={thisDay}
                  alt="user testimony"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <div
              className=" d-flex align-items-center justify-content-center pt-5"
              style={{ boxSizing: "border-box" }}
            >
              <p className="clientCarouselText cText mb-5 font-regular"></p>
            </div>
          </div>
        </a>
      </div>
      <div
        className="slider-container-gray"
        style={{ boxSizing: "border-box" }}
      >
        <div className=" text-center pt-xs-2 pt-md-5 carousel-item-container">
          <div className=" d-flex align-items-center justify-content-center pt-5 ">
            <div className="clientCarousellmage">
              <img
                src={marketwatch}
                alt="user testimony"
                width="100%"
                height="100%"
              />
            </div>
          </div>

          <div
            className=" d-flex align-items-center justify-content-center pt-5"
            style={{ boxSizing: "border-box" }}
          >
            <p className="clientCarouselText cText mb-5 font-regular"></p>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMobile } from "react-device-detect";
import { getIPAddress } from "../redux/actions/userAction";
import widgetImage1 from "../assets/img/widgetImage1.png";
import widgetImage2 from "../assets/img/widgetImage2.png";
import widgetImage3 from "../assets/img/widgetImage3.png";
import widgetImage4 from "../assets/img/widImg1.png";
import widgetImage5 from "../assets/img/widImg2.png";
import exchangeWid from "../assets/img/exchange-wid.svg";
import exchangearrows from "../assets/img/exchange-arrows.svg";
import exchangebit from "../assets/img/exchange-bit.svg";
import exchangebars from "../assets/img/exchange-bars.svg";
import exchangeplus from "../assets/img/exchange-plus.svg";
import exchangepercent from "../assets/img/exchange-percent.svg";
import BuySellWidget4 from "../components/buySellWidget/BuySellWidget4";

const Partner = () => {
  const widgetImages = [
    {
      src: widgetImage1,
      id: 1,
    },
    {
      src: widgetImage2,
      id: 2,
    },
    {
      src: widgetImage3,
      id: 3,
    },
  ];
  const widgetImg = [
    {
      src: widgetImage3,
      id: 1,
    },
    {
      src: widgetImage4,
      id: 2,
    },
    {
      src: widgetImage5,
      id: 3,
    },
  ];

  const renderWidgetContent = (banner) => {
    if (isMobile) {
      return (
        <div className="d-flex justify-content-center render-widget-carousel-div">
          <div className="">
            <div className="widgetImages">
              <Carousel
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                interval={7200}
                showThumbs={false}
              >
                {banner.map((widget) => (
                  <img
                    src={widget.src}
                    width="100%"
                    height="100%"
                    key={widget.id}
                    alt="banners"
                  />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-between">
        {banner.map((widget) => (
          <div div className="widgetImages" key={widget.id}>
            <img src={widget.src} width="100%" height="100%" alt="widget" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <main>
        <section className="bg-white-grey  py-5 ">
          <div className="container pb-5">
            {" "}
            <p className="partner-header font-medium text-black">
              Become our partner and earn money with us.
            </p>
            <p className="partner-header font-medium text-black level2-header">
              Generate income on your website with the Tradefada Widget!
            </p>
            <p className="partner-sub-header  font-medium text-black mt-4">
              The Most Flexible Partner Profit System out There — Earn money
              while you sleep
            </p>
            <div className="short-rectangle my-5"></div>
            <div className="mt-5">
              <p className=" partner-mini-headings font-medium text-black">
                Introducing Tradefada Direct
              </p>
              <p className=" font-regular line-height-22 fnt-16 text-gray">
                We are annoucing our newest product; Tradefada Direct, The
                widget lets you transform your wesbite into a money making tool.
                For custom websites, we offer an elegant and easy-to-install
                widget or a neat button. Use the configurator below to get the
                code necessary for deploying the selected tool to your platform.
              </p>
              <p className="font-medium line-height-22 fnt-16 text-gray fnt-w-800">
                How to apply for Tradefada Direct partneship program:
              </p>
              <ul className="partnerUl font-regular line-height-22 fnt-16 text-gray ">
                <li>
                  {" "}
                  Register an account and complete verification on Tradefada.com{" "}
                </li>
                <li> Sign up on our partner site by filling the form</li>
                <li>Your application will be carefully reviewed</li>
                <li>
                  Upon approval, you will be contacted, account created and you
                  will be able to integrate the Tradafada widget on your website
                  and start earning money 24/7.
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <p className=" partner-mini-headings font-medium text-black mb-4">
                Widget
              </p>

              {renderWidgetContent(widgetImages)}
            </div>
            <div className="my-5 border-grey" />
            <div className="mt-4">
              <p className=" partner-mini-headings font-medium text-black mb-4">
                Place a source code to your web page
              </p>

              <div className="sourcecode-div-parent text-center bg-white">
                <div className="d-flex justify-content-center align-items-center sourcecode-div">
                  <button
                    type="button"
                    className="bg-darkred btn btn-lg text-white no-border no-outline rounded-input  fnt-18 font-bold transaction-button"
                    onClick={() =>
                      window.open(
                        `https://partner.tradefada.com/register`,
                        "_self"
                      )
                    }
                  >
                    Register to get Code
                  </button>
                </div>
                <div className="border-grey" />

                <p className="font-medium text-darkred fnt-18 line-height-22 mt-3">
                  Copy to clipboard
                </p>
              </div>
            </div>
            <div className="my-5 border-grey" />
            <div className="mt-4">
              <p className=" partner-mini-headings font-medium text-black mb-4">
                How It Works
              </p>

              <div className="d-flex flex-md-row flex-sm-column flex-wrap justify-content-center  align-items-center wid-div-parent   bg-darkpink py-sm-3">
                <div className="d-flex flex-column justify-content-center align-items-center wid-div-child">
                  <div>
                    <img
                      src={exchangeWid}
                      height="114px"
                      width="100%"
                      alt="exchange"
                    />
                  </div>
                  <div className="text-center mt-2  ">
                    <p className="fnt-16 fnt-w-800 line-height-22 text-black">
                      Place widget or button on your website
                    </p>
                  </div>
                </div>
                <div className="responsive-arrow my-sm-4" />
                <div className="d-flex flex-column justify-content-center align-items-center wid-div-child ">
                  <div>
                    <img src={exchangearrows} height="114px" alt="arrows" />
                  </div>
                  <div className="text-center mt-2">
                    <p className="fnt-16 fnt-w-800 line-height-22 text-black ">
                      Convert your active sessions to exchanges
                    </p>
                  </div>
                </div>

                <div className="responsive-arrow my-sm-4" />
                <div className="d-flex flex-column justify-content-center align-items-center wid-div-child">
                  <div>
                    <img src={exchangebit} height="114px" alt="bit" />
                  </div>
                  <div className=" text-center mt-2">
                    <p className="fnt-16 fnt-w-800 line-height-22 text-black ">
                      Get repays in BTC in your partner's profile
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5">
              <p className=" partner-mini-headings font-medium text-black">
                Add the Exchange on Your Site
              </p>
              <p className="font-bold line-height-22 fnt-16  text-black">
                {" "}
                That's what our widget will look like on your website
              </p>
              <p className=" font-regular line-height-22 fnt-16 text-gray">
                Our widget has been designed with the utmost care to the needs
                of an average crypto user. You can be sure that your users will
                love it!
              </p>
              <div className="mt-5">{renderWidgetContent(widgetImg)}</div>
            </div>
            <div className="my-5">
              <p className=" partner-mini-headings font-medium text-black mb-4">
                How you earn
              </p>

              <div className="d-flex  flex-sm-column  flex-md-row flex-wrap justify-content-center  align-items-center wid-div-parent   bg-white py-sm-3">
                <div className="d-flex flex-column justify-content-center align-items-center wid-div-child">
                  <div>
                    <img src={exchangeplus} height="114px" alt="plus" />
                  </div>
                  <div className="my-2 text-center">
                    <button
                      type="button"
                      className="bg-darkred btn btn-lg text-white no-border no-outline rounded-input  fnt-18 font-bold transaction-button"
                      onClick={() =>
                        window.open(`https://partner.tradefada.com/`, "_self")
                      }
                    >
                      Start Here
                    </button>
                  </div>
                </div>

                <div className="responsive-arrow " />

                <div className="d-flex flex-column justify-content-center align-items-center wid-div-child ">
                  <div>
                    <img src={exchangebars} height="114px" alt="bars" />
                  </div>
                  <div className="mt-2 text-center">
                    <p className="fnt-16 fnt-w-800 line-height-22 text-black ">
                      Place widget or button on your website
                    </p>
                  </div>
                </div>

                <div className="responsive-arrow " />

                <div className="d-flex flex-column justify-content-center align-items-center wid-div-child">
                  <div>
                    <img src={exchangepercent} height="114px" alt="percent" />
                  </div>
                  <div className="mt-2 text-center">
                    <p className="fnt-16 fnt-w-800 line-height-22 text-black ">
                      Get % in BTC for every exchange made
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="tradeDiv pb-4">
          <BuySellWidget4 />
        </section>
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tickers: state.rates.exchangeTickers,
    fetching: state.rates.fetchingExchangeTickers,
    ipAddress: state.user.ipAddress,
  };
};

const mapDispatchToProps = {
  getIPAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(Partner);

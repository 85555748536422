import config from "../config";


export const Environment = {
  Production: "production",
  Development: "development",
  Staging: "staging"
};
export const postData = async (url = "", headerVal = {}, data = {}) => {
  const headers = new Headers();
  for (const key in headerVal) {
    if (headerVal.hasOwnProperty(key)) {
      headers.append(key, headerVal[key]);
    }
  }
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: headers,
    body: JSON.stringify(data),
  });
  let result = await response.json();
  if (!response.ok) {
    throw new Error(result.error || result.message);
  }
  return result;
};

export const setURLParams = async (url = "", otherParams = {}) => {
  let params = { api_key: config.Keys.api_key, ...otherParams };
  let newURL = url;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      newURL.searchParams.append(key, params[key]);
    }
  }
  return newURL;
};

export const setURLApiKey = (url = "", otherParams = {}) => {
  let params = { api_key: config.Keys.api_key, ...otherParams };
  let newURL = url;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      newURL.searchParams.append(key, params[key]);
    }
  }
  return newURL;
};

export const setMoonPayURLParams = async (url = "", otherParams = {}) => {
   const appEnvironment = process.env.NODE_ENV;
  let params
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
   params = { apiKey: config.Keys.moonpay_Key, ...otherParams };
  } else{
    params = { apiKey: config.Keys.moonpay_TestAPIKey, ...otherParams };
  }
 let newURL = url;
  for (const key in params) {
     if (params.hasOwnProperty(key)) {
           newURL.searchParams.append(key, params[key]);
         }
  }
  return newURL;
};
export const setMoonPayBuyURLParams = async (url = "", otherParams = "") => {
   const appEnvironment = process.env.NODE_ENV;
  let params
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
   params = { apiKey: config.Keys.moonpay_Key, ...otherParams };
  } else{
    params = { apiKey: config.Keys.moonpay_TestAPIKey, ...otherParams };
  }
  let newURL = url;
  newURL.searchParams.append(params);
  return newURL;
};

export const setMoonPayURLApiKey = (url = "", otherParams = {}) => {
    const appEnvironment = process.env.NODE_ENV;
  let params
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
   params = { apiKey: config.Keys.moonpay_Key, ...otherParams };
  } else{
    params = { apiKey: config.Keys.moonpay_TestAPIKey, ...otherParams };
  }
  let newURL = url;
  for (const key in params) {
       if (params.hasOwnProperty(key)) {
          newURL.searchParams.append(key, params[key]);
    }
     }
 
  return newURL;
};

export const createFormData = async (formInput) => {
  const formData = new FormData();
  for (const key in formInput) {
    if (formInput.hasOwnProperty(key)) {
      formData.append(key.toString(), formInput[key]);
    }
  }
  return formData;
};

export const getMoonPayData = async (url = "", headerVal = {}, data = {}) => {
  const headers = new Headers();
  for (const key in headerVal) {
    if (headerVal.hasOwnProperty(key)) {
      headers.append(key, headerVal[key]);
    }}
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: headers,
  });
  let result = await response.json();
    if (!response.ok) {
    throw new Error(result.message);
  }
  return result;
};


export const getData = async (url = "", headerVal = {}, data = {}) => {
  const headers = new Headers();
  for (const key in headerVal) {
    if (headerVal.hasOwnProperty(key)) {
      headers.append(key, headerVal[key]);
    }}
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    headers: headers,
  });
  let result = await response.json();
   if (!response.ok) {
    throw new Error(result.error);
  }
  return result;
};

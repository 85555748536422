import {
  SET_RATE_ERROR,
  SET_CRYPTO_COINS,
  FETCHING_EXCHANGE_TICKER,
  FETCH_EXCHANGE_TICKER_SUCCESSFUL,
  SET_MARKET_STATS,
  LOADING_MARKET_STATS,
} from "../actions/types";

const initialState = {
  errorMsg: null,
  exchangeTickers: [],
  cryptocurrencies: null,
  fetchingExchangeTickers: false,
  globalMarketStats: [],
  loadingMarketStats: false,
};

const rateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CRYPTO_COINS:
      return { ...state, cryptocurrencies: payload };
    case SET_RATE_ERROR:
      return { ...state, errorMsg: payload };
    case SET_MARKET_STATS:
      return {
        ...state,
        globalMarketStats: payload,
        loadingMarketStats: false,
      };
    case LOADING_MARKET_STATS:
      return {
        ...state,
        loadingMarketStats: true,
      };

    case FETCHING_EXCHANGE_TICKER:
      return { ...state, fetchingExchangeTickers: true };
    case FETCH_EXCHANGE_TICKER_SUCCESSFUL:
      return {
        ...state,
        exchangeTickers: payload,
        fetchingExchangeTickers: false,
      };

    default:
      return state;
  }
};

export default rateReducer;

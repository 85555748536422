import LogRocket from "logrocket";
import axios from "axios";
import querystring from "querystring";
import {setURLParams } from "../../helpers/apiAccessMethods";
import {apiURL } from "../../services/config";
import Endpoints from "../../services/endpoints";
import { setMoonPayURLParams, getMoonPayData } from "../../helpers/apiAccessMethods";
import { apiMoonPayURL } from "../../services/config";
import {SET_NEWSLETTER_SUBSCRIPTION_SUCCESS, SET_NEWSLETTER_SUBSCRIPTION_ERROR,RESET,FETCH_IP_ADDRESS} from './types';

export const getIPAddress = () => async (dispatch) => {
  try {
    const url = new URL(`${apiMoonPayURL()}${Endpoints.ipAddress}`);
    const urlParams = await setMoonPayURLParams(url);
    const currencies = await getMoonPayData(urlParams);
    dispatch(setIpAddress(currencies));
     } catch (error) {
    LogRocket.error(error.message);
  }
};

export const setNewsletterSubscription = (formData) => async (dispatch) => {
  dispatch(clearNewsLetterResponseHandler());

    try {
      let url = new URL(`${apiURL()}${Endpoints.NewsLetterSubscription}`);
      const payload = { email: formData.email, username: formData.email }
      const urlParams = await setURLParams(url,formData);
      const config = {
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
      };
    
    axios
      .post(urlParams, querystring.stringify(payload), config)
        .then((res) => {
          if (res.data.code === 200 || res.data.code === 201) {
                dispatch(setNewLetterSubSuccess("You have successfully subscribed to our newsletter."))
            }
            else {
             dispatch(setNewLetterSubError(res.data.message))
            };
      })
      .catch((error) => {

           dispatch(setNewLetterSubError(error.response.data.message));
        });
  } catch (error) {
   dispatch(setNewLetterSubError(error));
   LogRocket.error(error.message);
  }
  }

const setNewLetterSubError = (data) => ({
  type: SET_NEWSLETTER_SUBSCRIPTION_ERROR,
  payload: data,
});

const setNewLetterSubSuccess = (data) => ({
  type: SET_NEWSLETTER_SUBSCRIPTION_SUCCESS,
  payload: data,
});


export const clearNewsLetterResponseHandler = () => ({
  type: RESET,
});
 
const setIpAddress=(data) =>({
  type:FETCH_IP_ADDRESS,
  payload: data,
});
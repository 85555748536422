import React, { useEffect, useState, Fragment } from "react";
import { useRates } from "../../redux/selectors";
import { formatNumberToLocale } from "../../helpers/numberFormatter";
import WidgetModal from "./WidgetModal";

const BuySellWidget4 = () => {
  const [iframeloading, setIframeLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [fiatcurrency, setFiatCurrency] = useState([]);
  const [index, setIndex] = useState(0);
  const rates = useRates();
  const cryptocurrencies = rates.cryptocurrencies;

  const handleIframe = () => {
    setShowModal(true);
    setIframeLoading(false);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const tick = () => setIndex((i) => i + 1);
    const id = setInterval(tick, 3000);
    return () => clearInterval(id);
  }, []);

  const setFiatcurrencies = (cryptocurrencies) =>
    cryptocurrencies.map((coin) => ({
      key: coin.name,
      value: coin.code,
      label: coin.code.toUpperCase(),
      ...coin,
    }));

  useEffect(() => {
    if (cryptocurrencies) {
      setFiatCurrency(
        setFiatcurrencies(cryptocurrencies).filter((cur) => cur.type === "fiat")
      );
    }
    return () => {};
    // eslint-disable-next-line
  }, [cryptocurrencies]);

  return (
    <Fragment>
      <div className="container text-white  text-center">
        <h3 className="pt-5 font-medium">
          How much Cryptocurrency do you want to trade?
        </h3>
        <p className="fnt-15 pt-2 font-regular">
          You can buy / sell as little as{" "}
          {fiatcurrency[index % fiatcurrency.length] && (
            <span>
              {fiatcurrency[index % fiatcurrency.length].label}{" "}
              {formatNumberToLocale(
                fiatcurrency[index % fiatcurrency.length].minAmount,
                2
              )}{" "}
            </span>
          )}
          worth of your favorite Cryptocurency{" "}
        </p>{" "}
        <button
          className="calculator-card-btn-green-background text-white no-border no-outline rounded-input calculator-card-btn text-center px-md-5  font-heavy "
          type="button"
          onClick={handleIframe}
        >
          Buy / Sell Now
        </button>
      </div>
      <div className="container text-white ">
        {" "}
      
        <WidgetModal
          handleClose={handleClose}
          iframeloading={iframeloading}
          showModal={showModal}
        />
      </div>
    </Fragment>
  );
};

export default BuySellWidget4;

import LogRocket from "logrocket";
import config from "../../config";
import { FETCH_YOUTUBE_VIDEOS, FETCHING_YOUTUBE_VIDEOS } from "./types";

export const getYoutubeVideos = (inputs) => async (dispatch) => {
  let queryString;

  if (inputs.maxResults && inputs.maxResults !== null) {
    queryString = `maxResults=${inputs.maxResults}&`;
  }
  if (inputs.pageToken && inputs.pageToken !== null) {
    queryString += `pageToken=${inputs.pageToken}&`;
  }
  dispatch(setIsLoading());
  try {
    const url = `${config.Urls.youtubeApi}?part=snippet&playlistId=PLU0DZczHWvWTNNHIaS4ZH-0209DHGCveX&${queryString}key=${config.Keys.youtube_Key}`;

    const res = await fetch(url);
    const resData = await res.json();

    dispatch(setYoutubeVideos(resData));
  } catch (error) {
    LogRocket.error(error.message);
  }
};

const setYoutubeVideos = (data) => ({
  type: FETCH_YOUTUBE_VIDEOS,
  payload: data,
});

const setIsLoading = () => ({
  type: FETCHING_YOUTUBE_VIDEOS,
});

import React,{useEffect} from 'react'
import { Fragment } from 'react';
import { useForm } from "react-hook-form";
import { Row, Col,   FormGroup } from 'reactstrap';


const OptInForm = (props) => {
    const {setNewsletterSubscription,successMsg,errorMsg,clearNewsLetterResponseHandler}=props
    const { register, reset, errors, handleSubmit } = useForm({});
    // const[responseMessage, setResponseMessage]= useState("")
   
    const onSubmit = (data) => {
        data.tagnames = "opt-in"
       
        if (Object.keys(errors).length === 0) {
            setNewsletterSubscription(data)
            // window.location.replace("https://exchange.tradefada.com/signupapp");
         window.open(`https://exchange.tradefada.com/signupapp`,"_self" )
        }
       
     }

    useEffect(() => {
      if (errorMsg) {
        setTimeout(() => {
          clearNewsLetterResponseHandler();
          reset();
        }, 8000);
      }
      if (successMsg) {
        setTimeout(() => {
          clearNewsLetterResponseHandler();
          reset();
        }, 5000);
      }
      // eslint-disable-next-line
    }, [errorMsg, successMsg]);
   
    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col lg={7} xs={12} md={12}>
							<FormGroup>
                                <input type="email" name="email"  ref={register({ required: true })} className="address-box form-control rounded-input bg-gray text-gray no-border font-medium space--top-20px"
                                placeholder="Email Address" />
                                     {errors.email && (<p className="text-darkred fnt-12" >Your Email id is required.</p>)}
							</FormGroup>
						</Col>
						<Col lg={5} xs={12} md={12}>
							<FormGroup>
								<button  className="go-global no-border no-outline rounded-input space--top-20px" type="submit" >GO GLOBAL </button>
							</FormGroup>
						</Col>
					</Row>
			</form>
        
        </Fragment>
    )
}
export default OptInForm
import React, { Fragment } from "react";

import BuySellWidget4 from "../components/buySellWidget/BuySellWidget4";

import VideoGallery from "../components/videoSection/VideoGallery";

const VideoGalleryPage = () => {
  return (
    <Fragment>
      <main>
        <section className="bg-white py-5 ">
          <div className="container">
            <VideoGallery />
          </div>
        </section>

        <section className="tradeDiv pb-4">
          <BuySellWidget4 />
        </section>
      </main>
    </Fragment>
  );
};

export default VideoGalleryPage;

import React,{useState} from 'react'
 

const BuySellWidget3 = () => {
     const [iframeloading, setIframeLoading] = useState(true)
    return (
        <div>
              {iframeloading ? <div className="loaderImage"></div> : null}
            <iframe
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          src="https://tradefada-buy-sell-widget.azurewebsites.net/?api_key=265f050fb4ed720199a4087d1adaf896837c22d9a0155290201fafe6d571b67d"
           onLoad={() => setIframeLoading(false)}
          title="Tradefada"
                style={{ borderStyle: "none" }}
             className="widgetIframe"
        >
          <p>Your browser does not support iframes.</p>
        </iframe> 
        </div>
    )
}

export default BuySellWidget3
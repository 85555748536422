import LogRocket from "logrocket";
import axios from "axios";
import {
  setMoonPayURLParams,
  getMoonPayData,
} from "../../helpers/apiAccessMethods";
import { apiMoonPayURL } from "../../services/config";
import Endpoints from "../../services/endpoints";
import {
  SET_CRYPTO_COINS,
  FETCHING_EXCHANGE_TICKER,
  FETCH_EXCHANGE_TICKER_SUCCESSFUL,
  SET_MARKET_STATS,
  LOADING_MARKET_STATS,
} from "./types";

export const getCryptoCurrencies = () => async (dispatch) => {
  try {
    const url = new URL(`${apiMoonPayURL()}${Endpoints.MoonPayCurrencies}`);
    const urlParams = await setMoonPayURLParams(url);
    const currencies = await getMoonPayData(urlParams);
    dispatch(setCryptoCurrencies(currencies));
  } catch (error) {
    LogRocket.error(error.message);
  }
};

export const setGlobalMarketStats = () => async (dispatch) => {
  dispatch(marketStatsLoading());
  try {
    let url = new URL(`${Endpoints.MarketStatsEndpoint}`);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer tf_b75776baed29ca122808864425a4ee78bb17d99bf3e866d9110d5f41a5ce61d2_ng",
      },
    });

    if (response.status === 200) {
      const data = response.data.data;
      dispatch(fetchMarketStats(data));
    }
  } catch (error) {
    LogRocket.error(error.message);
  }
};

const setCryptoCurrencies = (data) => ({
  type: SET_CRYPTO_COINS,
  payload: data,
});

export const fetchExchangeTicker = () => async (dispatch) => {
  dispatch({ type: FETCHING_EXCHANGE_TICKER });
  const response = await axios.get(Endpoints.ExchangeTicker);
  if (response.status === 200) {
    const data = response.data.data;
    dispatch({ type: FETCH_EXCHANGE_TICKER_SUCCESSFUL, payload: data });
  }
};
const fetchMarketStats = (data) => ({
  type: SET_MARKET_STATS,
  payload: data,
});
const marketStatsLoading = () => ({
  type: LOADING_MARKET_STATS,
});

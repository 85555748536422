import React, { useState, Suspense } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { MobileView, isMobile } from "react-device-detect";
import WidgetModal from "../buySellWidget/WidgetModal";
// import money from "../../assets/img/adIcon/admoney.png";
import coin from "../../assets/img/adIcon/tt.png";
import block from "../../assets/img/adIcon/intotheblock.png";
import usdcCoin from "../../assets/img/adIcon/usdc.png";
import bantuCoin from "../../assets/img/adIcon/bantu-coin.png";
import tfLogo from "../../assets/img/tf-logo1.png";
import scale from "../../assets/img/adIcon/scale.png";

import "./Advert.css";

export const Advert = ({ handleCloseButton }) => {
  //   const renderContent = (banner) => {
  //     if (isMobile || MobileView) {
  //       return <img src={banner.mobileImage} key={banner.id} alt="banner one" />;
  //     }
  //     return <img src={banner.desktopImage} key={banner.id} alt="banner two" />;
  //   };
  // const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [iframeloading, setIframeLoading] = useState(true);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
    setIframeLoading(false);
  };
  const handleBantuLink = () => {
    window.open(`https://exchange.tradefada.com/trading-contest/138`, "_self");
  };
  return (
    <div className="main-container">
      <div className="d-flex align-items-center justify-content-start  ">
        <span className="closebutton text-center" onClick={handleCloseButton}>
          &times;
        </span>
      </div>

      <Carousel
        autoPlay
        infiniteLoop
        showIndicators={false}
        showStatus={false}
        interval={7200}
        showThumbs={false}
      >
        {/* {banners.map((banner) => {
          return renderContent(banner);
        })} */}
        <div className="bottom-bar-container  ad-bg-deep-blue-pattern">
          <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
            <div className="ad-img-div  align-self-md-center align-self-start order-2 order-md-1">
              <img
                src={block}
                alt="into the block analytics"
                width="100%"
                height="100%"
              />
            </div>
            <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
              {" "}
              <div>
                Explore the markets highs and lows with our
                <span
                  className="bold-top-ad-text"
                  style={{ paddingLeft: "3px" }}
                >
                  {" "}
                  Token and Signals
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end  text-center ad-bg-white ad-text-deep-blue ad-semi-curved-border order-3 order-md-3 ad-bottom-buttom">
              {" "}
              <Link
                className=" ad-bg-white ad-text-deep-blue ad-button-text "
                to="/analytics"
                rel="noopener noreferrer"
              >
                Dive Deep
              </Link>
            </div>
          </div>
        </div>
        <div className="bottom-bar-container  ad-bg-blue-pattern">
          <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
            <div className="bluecoin-image   align-self-md-center align-self-start order-2 order-md-1">
              <img src={usdcCoin} alt="usdc Coin" width="100%" height="100%" />
            </div>
            <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
              {" "}
              <div>
                Now you can sell your
                <span
                  className="bold-top-ad-text"
                  style={{ paddingLeft: "3px", paddingRight: "3px" }}
                >
                  {" "}
                  USDC
                </span>{" "}
                to local currency instantly
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-bottom-buttom  text-center  ad-bg-white ad-text-deep-blue ad-button-text ad-semi-curved-border order-3 order-md-3"
              onClick={() => handleOpenModal()}
            >
              {" "}
              Sell Now
            </div>
          </div>
        </div>
        <div className="bottom-bar-container  ad-bg-green-pattern">
          <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
            <div className="ad-img-div-usdt  align-self-md-center align-self-start order-2 order-md-1">
              <img src={coin} alt="usdt coin" width="100%" height="100%" />
            </div>
            <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
              {" "}
              <div>
                Now you can sell your
                <span
                  className="bold-top-ad-text"
                  style={{ paddingLeft: "3px", paddingRight: "3px" }}
                >
                  {" "}
                  USDT
                </span>{" "}
                to local currency instantly
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-bottom-buttom text-center  ad-bg-white ad-text-deep-green ad-semi-curved-border order-3 order-md-3 ad-button-text"
              onClick={() => handleOpenModal()}
            >
              {" "}
              Sell Now
            </div>
          </div>
        </div>
        <div className="bottom-bar-container ad-bg-margin-pattern">
          <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
            <div className="bluecoin-image  align-self-md-center align-self-start order-2 order-md-1 pt-md-2">
              <img
                src={scale}
                alt="margin trading"
                width="100%"
                height="100%"
              />
            </div>
            <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap pt-1 ">
              {" "}
              <div>Margin Trading Is Now Available!</div>
            </div>
            <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-bottom-buttom text-center   order-3 order-md-3 ">
              {" "}
              <img src={tfLogo} alt="logo" width="100%" height="100%" />
            </div>
          </div>
        </div>
        <div className="bottom-bar-container ad-bantu-bg-black">
          <div
            className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center pt-2 pb-1"
            onClick={() => handleBantuLink()}
          >
            <div className="bantucoin-image  align-self-md-center align-self-start order-2 order-md-1">
              <img
                src={bantuCoin}
                alt="bantu coin icon"
                width="100%"
                height="100%"
              />
            </div>

            <div className="d-flex flex-sm-column flex-md-row align-self-end align-self-md-center  ad-text-white text-center order-3 order-md-3 ">
              <div className="bantu-ad-text mr-md-3 mr-sm-0">XBN/USDT</div>
              <div
                className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-bantu-button text-center  ad-bg-teal  ad-semi-curved-border ad-button-text"
                onClick={() => handleBantuLink()}
              >
                {" "}
                Trade To Win
              </div>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end flex-sm-column flex-md-row order-3 order-md-3 ad-text-white text-center ">
              <div className="mr-md-2 mr-1 bantu-ad-text">XBN/USDT</div>
              <div
                className=" d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-bottom-buttom  ad-bg-teal   ad-semi-curved-border ad-button-text"
                onClick={() => handleOpenModal()}
              >
                {" "}
                Trade To Win
              </div>
            </div> */}
          </div>
        </div>
      </Carousel>
      {showModal && (
        <div style={{ position: "relative" }}>
          <Suspense>
            <WidgetModal
              showModal={showModal}
              handleClose={handleCloseModal}
              iframeloading={iframeloading}
            />
          </Suspense>
        </div>
      )}
    </div>
  );
};

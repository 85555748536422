import React, { useEffect, useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import UseAnimations from "react-useanimations";
import loading from "react-useanimations/lib/loading";
import unohome from "../../assets/img/home.webp";
import unocoins from "../../assets/img/coin2.webp";
import pos from "../../assets/img/pos-new--2.webp";
import unomoney from "../../assets/img/unomoney.webp";
import { TestimonyCarosels } from "../../components/carousel/TestimonyCarousel";
import { MediaCarosels } from "../../components/carousel/MediaCarosels";
import NewsLetterFormContainer from "../../components/newsLetterSubsription/NewsLetterFormContainer";
import { getIPAddress } from "../../redux/actions/userAction";
import OptInFormContainer from "../../components/optInForm/OptInFormContainer";
import { MapImg } from "../../assets/mapImage/MapImg";
import { ProductMarketCard } from "../../components/productMarketCard/ProductMarketCard";
import BuySellWidget3 from "../../components/buySellWidget/BuySellWidget3";
import BuySellWidget4 from "../../components/buySellWidget/BuySellWidget4";
import WidgetModal from "../../components/buySellWidget/WidgetModal";
import "../../assets/css/styles.css";
import { InfoSection } from "../../components/infoSection/InfoSection";

import { HeaderMenuButtons } from "../../components/layout/header/Header";
import VideoSection from "../../components/videoSection/VideoSection";

const GlobalMarkets = lazy(() =>
  import("../../components/globalMarketCard/GlobalMarkets")
);

const NotAvailableModal = lazy(() =>
  import("../notAvailablePage/NotAvailableContainer")
);

const Landing = (props) => {
  const {
    // tickers,
    getIPAddress,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [iframeloading, setIframeLoading] = useState(true);
  const [iframeModal, setIframeModal] = useState(false);
  const renderLoader = () => (
    <UseAnimations
      className="mx-auto"
      style={{ marginTop: "5px", marginBottom: "5px" }}
      animation={loading}
      size={100}
      strokeColor={"#af0202"}
    />
  );
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseIframeModal = () => {
    setIframeModal(false);
  };
  const handleIframe = () => {
    setIframeModal(true);
    setIframeLoading(false);
  };

  useEffect(() => {
    getIPAddress();
    return () => {};
  }, [getIPAddress]);

  return (
    <main>
      <section className="bg-white-grey  pb-5 ">
        <div className="pt-2 ">
          {" "}
          <HeaderMenuButtons />
        </div>

        <div className="container py-5">
          <Row>
            <Col xs="12" md="6" lg="7" className="text-gray d-none d-md-block">
              <Row>
                <Col xs="12">
                  <MapImg />{" "}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  {" "}
                  <h1 className="hero-header font-bold text-gray">
                    Trade Globally.
                  </h1>
                  <p className="font-demiBold fnt-17 text-gray space--top-10px">
                    Liquidity on over 100 trading pairs.
                  </p>
                  <div className="short-rectangle mt-3 mb-3"></div>
                  <div className="mt-3">
                    <div className="fnt-15 mb-3 text-gray font-medium">
                      One click to get started
                    </div>
                    <OptInFormContainer />
                  </div>
                </Col>
              </Row>{" "}
            </Col>
            <Col xs="12" md="6" lg="5" className="text-gray">
              <BuySellWidget3 />
            </Col>
            <Col
              xs="12"
              md="6"
              lg="7"
              className="mt-5 text-gray d-block d-md-none"
            >
              <Row>
                <Col xs="12">
                  {" "}
                  <h1 className="hero-header font-bold text-gray">
                    Trade Globally.
                  </h1>
                  <p className="font-demiBold fnt-17 text-gray space--top-10px">
                    Liquidity on over 100 trading pairs.
                  </p>
                  <div className="short-rectangle mt-3 mb-3"></div>
                  <div className="mt-3">
                    <div className="fnt-15 mb-3 text-gray font-medium">
                      One click to get started
                    </div>
                    <OptInFormContainer />
                  </div>
                </Col>
              </Row>{" "}
            </Col>
          </Row>
        </div>
      </section>
      <Suspense fallback={renderLoader()}>
        {/* <section className=" videoSection  ">
          <div className="container text-white  ">
            <div className="d-flex">
              <div>
                <p className="pt-5 font-bold unosectitle">Unobtanium</p>
                <p className="fnt-17 mt-1 font-demiBold">
                  The platinum to bitcoins Gold
                </p>
                <div className="vidTextDiv mb-4 mt-5">
                  <p>Rare, fair, the original crypto commodity</p>
                </div>
                <button
                  className="play-vid-button no-border no-outline text-center"
                  onClick={() =>
                    window.open(`https://unobtanium.uno/`, "_self")
                  }
                >
                  <FontAwesomeIcon icon={faPlay} className="ml-xs-1 ml-md-2" />
                  <span className="ml-3 font-bold">LEARN MORE</span>
                </button>
              </div>
              <div className="ml-auto d-none d-md-none  d-lg-block  d-xl-block">
                <SpinningCoin />
              </div>
            </div>
          </div>
        </section>

        <section className=" container  text-center ">
          <div className="storeImageDiv">
            <h2 className="pt-xs-4 pt-md-5 font-bold">
              The most advanced, easy-to-use <br />
              crypto exchanged. Award Winners 2021 | PAN Finance
            </h2>
            <p className="testimonyText text-lighter-grey my-4 font-medium">
              Your all-in-one access to the cryptocurrency world
            </p>
          </div>

          <div className=" d-flex     storeImagebtnDiv">
            <div className="d-flex align-items-center storeImagebtn">
              <img
                src={appleImage}
                alt="playstore"
                width="100%"
                height="100%"
                onClick={() =>
                  window.open(
                    `https://apps.apple.com/ng/app/tradefada-global/id1540975439`,
                    "_self"
                  )
                }
              />
            </div>
            <div className="d-flex align-items-center storeImagebtn">
              <img
                src={playImage}
                alt="playstore"
                width="100%"
                height="100%"
                onClick={() =>
                  window.open(
                    `https://play.google.com/store/apps/details?id=tradefada.com.exchange.app`,
                    "_self"
                  )
                }
              />
            </div>
          </div>
          <div className="d-flex  justify-content-between phoneimgdiv">
            <div className=" phoneimages phoneimage-left"></div>
            <div className=" phoneimages phoneimage-right"></div>
          </div>
        </section> */}
        <section className="container p-3 pb-md-2">
          <VideoSection />
        </section>
        <section className="bg-white-grey  p-5 pb-md-1">
          <div className="container">
            {" "}
            <InfoSection />
          </div>
        </section>

        <section className="tradeDiv justify-content-center pb-4 mb-3">
          <BuySellWidget4 />
        </section>
        <section className="text-gray mt-3">
          <GlobalMarkets />
        </section>
        <section className="mb-5  ">
          <div className="d-flex justify-content-center mb-5 pt-3 ">
            <div
              className="text-center mt-xs-3 mt-md-5"
              style={{ width: "555px" }}
            >
              <h2 className="font-bold">Do more with Tradefada</h2>
              <p className="imText text-lighter-grey mt-3 font-medium px-2">
                Explore different industries you can benefit on Tradefada.
              </p>
            </div>
          </div>

          <div className=" unocontainer mt-3 d-sm-flex justify-content-between">
            <ProductMarketCard
              title="Real Estate"
              text="Access interest free 10-year tokenized real estate in Nigeria.Make money selling real estate tokens"
              bgColor="#FFD8AB"
              bgImage={unohome}
              className="productCard-left"
              onClick={handleOpenModal}
            />
            <ProductMarketCard
              title="Tradefada Exchange"
              text="Newbie or Pro, build your cryptocurrency portfolio with access to the most robust crypto exchange with deep liquidity and low fees"
              bgColor="#FFE4E4"
              bgImage={unocoins}
              className="productCard-right"
              onClick={handleIframe}
            />
          </div>

          <div className="  unocontainer mt-3  d-sm-flex justify-content-between">
            <ProductMarketCard
              title="Agent Network"
              text="Make passive income by becoming a bitcoin agent.Get access to over 10,000+ walk in agents across Nigeria & Africa"
              bgColor="#C0E3FF"
              bgImage={pos}
              className="productCard-left"
              onClick={handleOpenModal}
            />
            <ProductMarketCard
              title="Tradefada for Business"
              text="Make money by accepting crypto payments for your business using our merchant."
              bgColor="#CAFFF5"
              bgImage={unomoney}
              className="productCard-right"
              onClick={handleOpenModal}
            />
          </div>
        </section>

        <section className="bg-white">
          <TestimonyCarosels />
        </section>
        <section className="contactsection">
          <div className=" container my-4 py-1">
            <div className="row">
              <div className="col-md-7 col-sm-12 col-xs-12 mx-xs-auto  ">
                <p className="contact-title mt-5 mb-4 font-demiBold">
                  Stay in Touch
                </p>
              </div>
            </div>
            <NewsLetterFormContainer />
          </div>
        </section>
        <section className="bg-carosel">
          <div className="container">
            <MediaCarosels />
          </div>
        </section>

        <NotAvailableModal show={showModal} handleClose={handleCloseModal} />
      </Suspense>
      <Suspense>
        <WidgetModal
          showModal={iframeModal}
          handleClose={handleCloseIframeModal}
          iframeloading={iframeloading}
        />
      </Suspense>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    tickers: state.rates.exchangeTickers,
    fetching: state.rates.fetchingExchangeTickers,
    ipAddress: state.user.ipAddress,
  };
};

const mapDispatchToProps = {
  getIPAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);

import { combineReducers } from "redux";
import rateReducer from "./ratesReducer";
import userReducer from "./userReducer";
import videosReducer from "./videosReducer";

import adReducer from "./adReducer";

export default combineReducers({
  rates: rateReducer,
  user: userReducer,
  adverts: adReducer,
  videos: videosReducer,
});

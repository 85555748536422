import { SET_AD, AD_ERROR } from "./types";
import { Link } from "react-router-dom";
import coin from "../../assets/img/adIcon/tt.png";
import block from "../../assets/img/adIcon/intotheblock.png";
import banner from "../../assets/img/banner.png";
import usdcCoin from "../../assets/img/adIcon/usdc.png";

const banners = [
  {
    id: 1,
    mobileImage: (
      <div className="bottom-bar-container  ad-bg-green-pattern">
        <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
          <div className="ad-img-div-money  align-self-md-center align-self-start order-2 order-md-1">
            <img src={coin} alt="money" width="100%" height="100%" />
          </div>
          <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
            {" "}
            <div>
              Now you can sell your
              <span
                className="bold-top-ad-text"
                style={{ paddingLeft: "3px", paddingRight: "3px" }}
              >
                {" "}
                USDT
              </span>{" "}
              to local currency instantly
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-top-button  text-center  ad-bg-white ad-text-deep-green ad-semi-curved-border order-3 order-md-3">
            {" "}
            <Link
              className="ad-bg-white ad-text-deep-green ad-button-text "
              to="/"
              rel="noopener noreferrer"
            >
              Sell Now
            </Link>
          </div>
        </div>
      </div>
    ),
    desktopImage: banner,
  },
  {
    id: 2,
    mobileImage: (
      <div className="bottom-bar-container  ad-bg-blue-pattern">
        <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
          <div className="ad-img-div-money  align-self-md-center align-self-start order-2 order-md-1">
            <img src={usdcCoin} alt="usdc Coin" width="100%" height="100%" />
          </div>
          <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
            {" "}
            <div>
              Now you can sell your
              <span
                className="bold-top-ad-text"
                style={{ paddingLeft: "3px", paddingRight: "3px" }}
              >
                {" "}
                USDC
              </span>{" "}
              to local currency instantly
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-top-button  text-center  ad-bg-white ad-text-deep-blue ad-semi-curved-border order-3 order-md-3">
            {" "}
            <Link
              className=" ad-bg-white ad-text-deep-blue ad-button-text "
              to="/"
              rel="noopener noreferrer"
            >
              Sell Now
            </Link>
          </div>
        </div>
      </div>
    ),
    desktopImage: banner,
  },
  {
    id: 3,
    mobileImage: (
      <div className="bottom-bar-container  ad-bg-deep-blue-pattern">
        <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
          <div className="ad-img-div  align-self-md-center align-self-start order-2 order-md-1">
            <img
              src={block}
              alt="into the block analytics"
              width="100%"
              height="100%"
            />
          </div>
          <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
            {" "}
            <div>
              Explore the markets highs and lows with our
              <span className="bold-top-ad-text" style={{ paddingLeft: "3px" }}>
                {" "}
                Token and Signals
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-top-button  text-center ad-bg-white ad-text-deep-blue ad-semi-curved-border order-3 order-md-3">
            {" "}
            <Link
              className=" ad-bg-white ad-text-deep-blue ad-button-text "
              to="/analytics"
              rel="noopener noreferrer"
            >
              Dive Deep
            </Link>
          </div>
        </div>
      </div>
    ),
    desktopImage: banner,
  },
];

export const getAdverts = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_AD,
      payload: banners,
    });
  } catch (err) {
    dispatch({
      type: AD_ERROR,
      payload: err.message,
    });
  }
};

import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import money from "../../../assets/img/adIcon/admoney.png";
import block from "../../../assets/img/adIcon/intotheblock.png";

import "./Adbar.css";

const AdBar = () => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showIndicators={false}
      showStatus={false}
      interval={9000}
      showThumbs={false}
    >
      <div className="top-bar-container  ad-bg-red">
        <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
          <div className="ad-img-div  align-self-md-center align-self-start order-2 order-md-1">
            <img
              src={block}
              alt="into the block analytics"
              width="100%"
              height="100%"
            />
          </div>
          <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap  ">
            {" "}
            <div>
              Explore the markets highs and lows with our
              <span className="bold-top-ad-text" style={{ paddingLeft: "3px" }}>
                {" "}
                Token and Signals
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-top-button ad-bg-white text-center ad-text-red ad-semi-curved-border order-3 order-md-3">
            {" "}
            <Link
              className="ad-text-red ad-button-text "
              to="/analytics"
              rel="noopener noreferrer"
            >
              Dive Deep
            </Link>
          </div>
        </div>
      </div>
      <div className="top-bar-container  ad-bg-grey">
        <div className="topbar-div d-flex flex-column flex-md-row justify-content-between align-items-center py-2 ">
          <div className="ad-img-div-money  align-self-md-center align-self-start order-2 order-md-1">
            <img src={money} alt="money" width="100%" height="100%" />
          </div>
          <div className="align-self-center align-self-md-center  top-ad-text ad-text-white text-center  order-1 order-md-2 d-flex sm-wrap   ">
            {" "}
            <div> Install our widget on your website and start earning</div>
          </div>
          <div className="d-flex justify-content-center align-items-center align-self-md-center align-self-end ad-top-button ad-bg-red text-center ad-text-white  ad-semi-curved-border order-3 order-md-3">
            {" "}
            <Link
              className="ad-text-white ad-button-text "
              to="/partner"
              rel="noopener noreferrer"
            >
              Become Partner
            </Link>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default AdBar;

import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Header } from "./components/layout/header/Header";
import Landing from "./pages/landingPage/Landing";
import Partner from "./pages/Partner";
import AnalyticPage from "./pages/AnalyticPage";
import VideoGalleryPage from "./pages/VideoGalleryPage";
import { Footer } from "./components/layout/footer/Footer";
import store from "./redux/store";
import "./App.css";
import WhatsAppChatWidget from "./components/WhatsappWidget"

function App() {
  return (
    <Provider store={store}>
      <Router forceRefresh={true}>
        <Fragment>
          <Header />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/partner" component={Partner} />
            <Route exact path="/videos" component={VideoGalleryPage} />
            <Route exact path="/analytics" component={AnalyticPage} />
          </Switch>
          <WhatsAppChatWidget />
          <Footer />
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell} from '@fortawesome/free-solid-svg-icons'
import { Modal } from "react-bootstrap";
import { Row, Col, } from 'reactstrap';
import { useForm } from "react-hook-form";
import  "./NotAvailable.css"


export const NotAvailableModal = ({
  modalTitle,
  show,
  handleClose, setNewsletterSubscription,successMsg,errorMsg,clearNewsLetterResponseHandler
}) => {
   
    const { register, reset, errors, handleSubmit } = useForm();
    const[responseMessage, setResponseMessage]= useState("")
   
    const onSubmit = (data) => {
         data.tagnames="updates"
           setNewsletterSubscription(data)
     }

    useEffect(() => {
        if (errorMsg) {
               if (errorMsg) {
                   setResponseMessage("You have previously subscribed for our updates. Enter a different email for a different subcription") 
               }
               
           setTimeout(() => {
           setResponseMessage("")
                clearNewsLetterResponseHandler();
                 reset()
           }, 8000);
    }
        if (successMsg) {
             setResponseMessage("Congratulations, you would be notified on new updates")
            setTimeout(() => {
                 setResponseMessage("")
             clearNewsLetterResponseHandler();
                reset()
            }, 5000);
    }
     }, [errorMsg, successMsg,clearNewsLetterResponseHandler,reset]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
         animation={false}
         size="md"
        
    >
      <Modal.Header closeButton >
        {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
      </Modal.Header>
          <Modal.Body scrollable="true">
              <div className="text-center py-4 py-md-4 px-md-5">
          <p className="line-height-50 font-bold fnt-50 text-gray">Coming Soon</p><p className="text-center fnt-50 text-darkred my-4">
          <FontAwesomeIcon icon={faBell} className=" bell" /></p>
                  <p className="text-gray">Remind me when this service is available</p>
                
                  <form onSubmit={handleSubmit(onSubmit)}>
                         <div className="mx-1">
                             {errors.email && (<p className="text-darkred fnt-12" >Your Email id is required.</p>)}
                             {errorMsg && (<p className="text-darkred fnt-12" >{responseMessage}</p>)}
                              {successMsg && (<p className="text-gray fnt-12" >{responseMessage}</p>)}
                        </div>
						<Row >
							<Col  xs={12}>
								<input   type="email" name="email" ref={register({ required: true })} id="exampleEmail" className="address-box form-control rounded-input bg-gray text-gray no-border font-medium mt-2 "
													placeholder="Email Address" />
              </Col>
            </Row>
            <Row>
              <Col  xs={6}>
								<button  className="go-global no-border no-outline rounded-input mt-3  " onClick={handleClose} >CLOSE </button>
							</Col>
							<Col  xs={6}>
								<button  className="go-global no-border no-outline rounded-input mt-3  " type="submit" >SUBMIT </button>
              </Col>
              	
						</Row>
					</form>
              </div></Modal.Body>
     	
    </Modal>
  );
};

export default NotAvailableModal;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Analytics.css";

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {}, [location]);

  return (
    <div className="itbIframe">
      <div className="widget-container ">
        <div data-target="itb-widget" data-type="quick-view-v2"></div>
        <div className="widget-footer d-flex flex-column flex-md-row ">
          <div data-target="itb-widget" data-type="call-to-action"></div>
          <div data-target="itb-widget" data-type="powered-by"></div>
        </div>
      </div>
    </div>
  );
};
export default Analytics;

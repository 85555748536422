import config from "../config";

export const Environment = {
  Production: "production",
  Development: "development",
  Staging: "staging"
};

export const apiURL = () => {
  const appEnvironment = process.env.NODE_ENV;
  let url = "";
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
    url = config.Urls.tradefadaProduction;
  } else if (
    appEnvironment.toLowerCase() === Environment.Development.toLowerCase()
  ) {
    url = config.Urls.tradefadaDevelopment;
  }
  return url;
};

export const apiMoonPayURL = () => {
  const appEnvironment = process.env.NODE_ENV;
  let url = "";
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
    url = config.Urls.moonPayBase;
  } else if (
    appEnvironment.toLowerCase() === Environment.Development.toLowerCase()
  ) {
    url = config.Urls.moonPayBase;
  }
  return url;
};

export const apiBuyMoonPayURL = () => {
 
  const appEnvironment = process.env.NODE_ENV;
  let url = "";
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
    url = config.Urls.moonpayBuyProduction;
  } else if (
    appEnvironment.toLowerCase() === Environment.Development.toLowerCase()
  ) {
    url = config.Urls.moonpayBuyStaging;
 
  }
  else if (
    appEnvironment.toLowerCase() === Environment.Staging.toLowerCase()
  ) {
    url = config.Urls.moonpayBuyStaging;
  
  }
  return url;
};

export const apiSellMoonPayURL = () => {
  const appEnvironment = process.env.NODE_ENV;
  let url = "";
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
    url = config.Urls.moonpaySellProduction;
  } else if (
    appEnvironment.toLowerCase() === Environment.Development.toLowerCase()
  ) {
    url = config.Urls.moonpaySellStaging;
  }
  else if (
    appEnvironment.toLowerCase() === Environment.Staging.toLowerCase()
  ) {
    url = config.Urls.moonpaySellStaging;
  }
  return url;
};

export const apiMoonPayApiKey = () => {
  const appEnvironment = process.env.NODE_ENV;
  let url = "";
  if (appEnvironment.toLowerCase() === Environment.Production.toLowerCase()) {
    url =config.Keys.moonpay_Key;
  } else if (
    appEnvironment.toLowerCase() === Environment.Development.toLowerCase()
  ) {
    url =config.Keys.moonpay_TestAPIKey;
  }
  else if (
    appEnvironment.toLowerCase() === Environment.Staging.toLowerCase()
  ) {
    url =config.Keys.moonpay_TestAPIKey;
  }
  return url;
};
const Endpoints = {
  BitcoinDollarRate: "currencies/blockchain/usdbtc",
  EthCoinRate: "/blockchain/rate/eth",
  // TradedCurrency: "https://api.cryptoexchange.com/api/2/public/currency",
  MoonPayCurrencies: "v3/currencies",
  AlphapointInstruments: "config/alphapoint/instruments",
  UnoUsdMarket: "config/alphapoint/getLevel",
  NewsLetterSubscription: "account/salesmanago/addContact",
  PriceRange:
    "https://tradefadav2apistaging.azurewebsites.net/get-rate-per-currency",
  Bid_prices:
    "v3/currencies/bid_price?cryptoCurrencies=aave,ada,algo,atom,ava,axs,band,bat,bch,bnb,bora,btc,btt,busd,celo,chz,comp,cvc,dai,dash,dgb,doge,dot,egld,eos,eosdt,eth,fil,fun,hbar,hive,kava,keth,klay,krt,link,ltc,luna,mana,matic,miota,mkr,nano,near,neo,ocean,okb,om,omg,ong,ont,pax,paxg,qtum,rep,rfuel,rinketh,rune,rvn,sand,sdt,snx,sol,srm,stmx,tomo,trx,tusd,uni,usdc,usdt,ust,utk,vet,waves,waxp,wbtc,xlm,xrp,xtz,zec,zil,zrx&fiatCurrencies=aud,bgn,brl,cad,chf,cny,cop,czk,dkk,dop,egp,eur,gbp,hkd,hrk,idr,ils,jod,jpy,kes,krw,kwd,lkr,mad,mxn,myr,ngn,nok,nzd,omr,pen,pkr,pln,ron,rub,sek,sgd,thb,try,twd,usd,vnd,zar",
  Ask_prices:
    "v3/currencies/ask_price?cryptoCurrencies=aave,ada,algo,atom,ava,axs,band,bat,bch,bnb,bora,btc,btt,busd,celo,chz,comp,cvc,dai,dash,dgb,doge,dot,egld,eos,eosdt,eth,fil,fun,hbar,hive,kava,keth,klay,krt,link,ltc,luna,mana,matic,miota,mkr,nano,near,neo,ocean,okb,om,omg,ong,ont,pax,paxg,qtum,rep,rfuel,rinketh,rune,rvn,sand,sdt,snx,sol,srm,stmx,tomo,trx,tusd,uni,usdc,usdt,ust,utk,vet,waves,waxp,wbtc,xlm,xrp,xtz,zec,zil,zrx&fiatCurrencies=aud,bgn,brl,cad,chf,cny,cop,czk,dkk,dop,egp,eur,gbp,hkd,hrk,idr,ils,jod,jpy,kes,krw,kwd,lkr,mad,mxn,myr,ngn,nok,nzd,omr,pen,pkr,pln,ron,rub,sek,sgd,thb,try,twd,usd,vnd,zar",
  ExchangeTicker:
    "https://tradefadav2apistaging.azurewebsites.net/ticker?api_key=26352ed56183c44ea1b71300a80f15e779c89bc860a4b091cadf7cfeddd8679c72c4e31a",
  ipAddress: "v4/ip_address",
  curBidPrice: "v3/currencies/bid_price",
  MarketStatsEndpoint:
    "https://tradefada-mobile-api.azurewebsites.net/currencies/historic",
};

export const CoinRateEndpoints = {
  BaseRateUrl:"https://tradefadav2apistaging.azurewebsites.net",
  BitcoinDollarRate: "/blockchain/rate/btc",
  EthCoinRate:"/blockchain/rate/eth",
  BtcPrices:"/btc-dollar",
  EthPrices:"/blockchain/getRates/eth",
 
  PriceRange:
    "/get-rate-per-currency",
 
};

export default Endpoints;

import React,{useEffect} from 'react'
import { Fragment ,useState} from 'react';
import { useForm } from "react-hook-form";


const NewsLetterForm = (props) => {
    const {setNewsletterSubscription,successMsg,errorMsg,clearNewsLetterResponseHandler}=props
    const { register, reset, errors, handleSubmit } = useForm();
    const[responseMessage, setResponseMessage]= useState("")
   
    const onSubmit = (data) => {
        data.tagnames="newsletter"
        setNewsletterSubscription(data)
     }

    useEffect(() => {
      if (errorMsg) {
        if (errorMsg === "Invalid new email specified") {
          setResponseMessage(
            "You have previously subscribed to our newsletter with this email. Enter a different email to subscribe"
          );
        } else {
          setResponseMessage(errorMsg);
        }

        setTimeout(() => {
          setResponseMessage("");
          clearNewsLetterResponseHandler();
          reset();
        }, 8000);
      }
      if (successMsg) {
        setResponseMessage(successMsg);
        setTimeout(() => {
          setResponseMessage("");
          clearNewsLetterResponseHandler();
          reset();
        }, 5000);
      }
      // eslint-disable-next-line
    }, [errorMsg, successMsg]);
   
    return (
        <Fragment>
         <form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
                    <div className="col-md-7 col-sm-12 col-xs-12 mx-xs-auto">
                        <div className="mx-1">
                             {errors.email && (<p className="text-darkred fnt-12" >Your Email id is required.</p>)}
                             {errorMsg && (<p className="text-darkred fnt-12" > {responseMessage}</p>)}
                              {successMsg && (<p className="text-gray fnt-12" > {responseMessage}</p>)}
                        </div>
                        <div className="subscribe-container  ">
                            <input
                                type="email"
                                name="email"
                                ref={register({ required: true })}
								className="form-control rounded-input bg-gray text-gray no-border mr-4 font-medium"
								placeholder="Enter email address for newsletter"
                                />
                             <button className="btn text-black btn-grey-outline no-outline rounded-input font-medium" type="submit">Subscribe</button>
                     </div>
                 </div>
				</div>
			</form>  
        </Fragment>
    )
}
export default NewsLetterForm
import { SET_AD, AD_ERROR  } from "../actions/types";

const initialState = {
  adverts: null,
  loading: true,
  errors: null,
};
const adReducer = (state = initialState, { type, payload })=>{

   
  switch (type) {
    case SET_AD:
   
      return {
        ...state,
        adverts:payload,
        loading: false,
      };
    case AD_ERROR:
      return {
        ...state,
        errors: [...payload],
        loading: false,
      };

    default:
      return state;
  }
}
export default adReducer;
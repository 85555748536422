import { useSelector } from "react-redux";
export const useRates = () => useSelector((state) => state.rates);
export const useTransactions = () => useSelector((state) => state.transactions);
export const useAdverts = () => useSelector((state) => state.adverts);
export const useVideos = () => useSelector((state) => state.videos);

// export const useAuthState = () => useSelector((state) => state.auth);
// export const useUserState = () => useSelector((state) => state.user);
// export const useAccountState = () => useSelector((state) => state.account);
// export const useTransactionState = () => useSelector((state) => state.transaction);
// export const useBankState = () => useSelector((state) => state.bank);
// export const useExchangeRates = () => useSelector((state) => state.exchangeTickers)

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useVideos } from "../../redux/selectors";
import { getYoutubeVideos } from "../../redux/actions/videosActions";
// import banner from "../../assets/img/banner.png";
// import isEmpty from "../../helpers/is-empty";
import "./VideoSection.css";
import VideoModal from "./videoModal/VideoModal";
import { Link } from "react-router-dom";

const VideoSection = () => {
  const dispatch = useDispatch();
  const videos = useVideos();

  const [youtubeVideos, setYoutubeVideos] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const data = {
    maxResults: 3,
  };
  useEffect(() => {
    dispatch(getYoutubeVideos(data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getYoutubeVideos]);

  useEffect(() => {
    if (videos.youtubeVideos && videos.youtubeVideos.length > 0) {
      setYoutubeVideos(videos.youtubeVideos);
    }
  }, [videos.youtubeVideos]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = (id) => {
    handleSelectedVideo(id);
    setShowModal(true);
  };
  const handleSelectedVideo = (id) => {
    const result = youtubeVideos.filter((item) => item.id === id);
    setSelectedVideo(result);
  };

  return (
    <div className="  pt-1 pt-md-5 mb-3  mb-md-4 px-xs-2 px-md-3">
      <p className="pt-1 pt-md-2   videosectionheader font-bold">
        How to videos
      </p>
      <p className="videosectionsubheading">
        {" "}
        Watch and learn more about using Tradefada
      </p>
      <p className="videosectiontext">
        A-Z of how to Buy, Sell, Trade and analyze the market
      </p>
      {/* <div className="d-flex justify-content-between align-items-center text-center mb-3 videoContainer"> */}
      <div className="row text-center mb-3 videoContainer">
        {youtubeVideos &&
          youtubeVideos !== null &&
          youtubeVideos.map((item) => {
            const { id, snippet = {} } = item;
            const { title, thumbnails = {} } = snippet;
            const { medium = {} } = thumbnails;

            return (
              <div key={id ? id : null} className="col-12 col-md-4 mt-3 ">
                <div className="videocards text-center">
                  {" "}
                  <img
                    width="100%"
                    height="100%"
                    src={medium.url}
                    className="videocardsimg"
                    alt={title}
                  />
                  <div
                    className="videocardsOverlay"
                    onClick={medium.url ? () => handleOpenModal(id) : null}
                  ></div>
                </div>

                <div className="videoText">
                  {medium.url ? title : "Video Unavailable"}
                </div>
              </div>
            );
          })}
      </div>
      <div className="text-center">
        <Link to="/videos">
          <button className="videoSeemoreButton no-border no-outline text-center">
            <span className="mr-3 font-bold">MORE VIDEOS</span>
            <FontAwesomeIcon icon={faPlay} className="ml-xs-1 ml-md-2" />
          </button>
        </Link>
      </div>
      <div className="container text-white ">
        {" "}
        <VideoModal
          handleClose={handleCloseModal}
          video={selectedVideo}
          showModal={showModal}
        />
      </div>
    </div>
  );
};
export default VideoSection;

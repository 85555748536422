import React, { Fragment, useState, Suspense } from "react";
import WidgetModal from "../../buySellWidget/WidgetModal";
import "./HeaderMenuButton.css";
import { useHistory } from "react-router-dom";

export const HeaderMenuButton = ({
  img,
  boldText,
  subText,
  linkRoute,
  locationRoute,
  additionalImg,
  showIframeModal,
  additionalImgwidth,
  additionalImgheight,
  height,
  width,
}) => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [iframeloading, setIframeLoading] = useState(true);
  const handleCloseModal = () => {
    setShowModal(false);
    showIframeModal = !showIframeModal;
  };

  const clickLink = () => {
    if (showIframeModal) {
      setShowModal(true);
      setIframeLoading(false);
    }
    if (locationRoute) {
      history.push(locationRoute);
    }
    if (linkRoute) {
      window.open(`${linkRoute}`, "_self");
    }
  };
  return (
    <Fragment>
      <div
        className="headerbutton d-flex justify-content-between align-item-center  my-2 my-md-3 "
        onClick={clickLink}
      >
        <div className="d-flex align-self-center ml-1  mr-1 ml-md-2 mr-md-3">
          {!additionalImg && (
            <img src={img} alt="bar Icon" width={width} height={height} />
          )}
          {additionalImg && (
            <Fragment>
              <img src={img} alt="bar Icon" width={width} height={height} />
              <img
                src={additionalImg}
                className="ml-1 ml-md-2"
                alt="bar Icon"
                width={additionalImgwidth}
                height={additionalImgheight}
              />
            </Fragment>
          )}
        </div>

        <div className="flex-wrap align-self-center headerbuttonboldText flex-grow-1">
          {boldText}
          {subText ? (
            <Fragment>
              <br />
              <span className="headerbuttonspan">{subText}</span>
            </Fragment>
          ) : null}
        </div>
      </div>

      {showModal && (
        <div style={{ position: "relative" }}>
          <Suspense>
            <WidgetModal
              showModal={showModal}
              handleClose={handleCloseModal}
              iframeloading={iframeloading}
            />
          </Suspense>
        </div>
      )}
    </Fragment>
  );
};

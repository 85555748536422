import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebook,
  faYoutube,
  faTelegram,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import tfIcon from "../../../assets/img/tfIcon.svg";
import phoneimg from "../../../assets/img/phoneIcon.png";
import googleImageg from "../../../assets/img/googleplay-g.webp";
import appleImage from "../../../assets/img/appledownloadlogo-g.png";
import NotAvailableModal from "../../../pages/notAvailablePage/NotAvailableContainer";
import { useAdverts } from "../../../redux/selectors";
import { Advert } from "../../adSection/Advert";
import { Link } from "react-router-dom";
import xlogo from "../../../assets/img/twitter-logo-png-white.png";

export const Footer = () => {
  const getCurrentYear = new Date().getFullYear();
  const [showModal, setShowModal] = useState(false);
  const [showAdsection, setShowAdSection] = useState(true);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const adverts = useAdverts();
  const banners = adverts.adverts;

  const handleShowAd = () => {
    setShowAdSection(false);
  };
  return (
    <Fragment>
      <section className="footerDiv ">
        <div className="container d-flex flex-row flex-wrap  justify-content-between pt-5 text-white">
          <div className="footermenu">
            <p className="footer-links-menu">UTILITY</p>

            <ul className="footerUI footer-link-text mb-3">
              <li>
                <a
                  href="https://api.exchange.tradefada.com/"
                  rel="noopener noreferrer"
                >
                  API
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/fix"
                  rel="noopener noreferrer"
                >
                  FIX
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/widget"
                  rel="noopener noreferrer"
                >
                  Price Ticker Widget
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/system-monitor "
                  rel="noopener noreferrer"
                >
                  System Health
                </a>
              </li>
            </ul>
          </div>
          <div className="footermenu">
            <p className="footer-links-menu ">SUPPORT</p>
            <ul className="footerUI footer-link-text mb-3">
              <li>
                <a
                  href="https://tradefada.freshdesk.com/support/solutions"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/terms-of-use"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/legal"
                  rel="noopener noreferrer"
                >
                  Policy Documents
                </a>
              </li>
              <li>
                <a
                  href="https://tradefada.freshdesk.com/support/home"
                  rel="noopener noreferrer"
                >
                  Announcements/ News
                </a>
              </li>
            </ul>
          </div>
          <div className="footermenu">
            <p className="footer-links-menu ">FEATURES</p>

            <ul className="footerUI footer-link-text mb-3">
              <li>
                <a
                  href="https://exchange.tradefada.com/btc-to-usdt"
                  rel="noopener noreferrer"
                >
                  Trading
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/account"
                  rel="noopener noreferrer"
                >
                  Deposit Naira
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/account"
                  rel="noopener noreferrer"
                >
                  Withdraw Naira
                </a>{" "}
              </li>
              <li>
                <Link to="/partner" rel="noopener noreferrer">
                  Become Partner
                </Link>{" "}
              </li>
              <li>
                <Link to="/analytics" rel="noopener noreferrer">
                  Analytics
                </Link>{" "}
              </li>
            </ul>
          </div>

          <div className="footermenu">
            <p className="footer-links-menu">ABOUT</p>

            <ul className="footerUI footer-link-text mb-1">
              <li>
                <a
                  href="https://exchange.tradefada.com/about-us"
                  rel="noopener noreferrer"
                >
                  About TradeFada
                </a>
              </li>
              <li>
                <a
                  href="https://exchange.tradefada.com/mediakit"
                  rel="noopener noreferrer"
                >
                  Press Kit
                </a>
              </li>
            </ul>
            <div className=" footer-address mb-3">
              <p className="footer-link-text">
                C129 Ikota, VGC, Lekki-Epe Express way Lagos - Nigeria
              </p>
            </div>
          </div>
          <div className="footermenu">
            <p className="footer-links-menu">MOBILE APP</p>

            <ul className="footerUI footer-link-text">
              <li>
                <a
                  href="https://apps.apple.com/ng/app/tradefada-global/id1540975439"
                  rel="noopener noreferrer"
                >
                  <div
                    style={{
                      width: "17px",
                      height: "17px",
                      display: "inline-block",
                    }}
                    className="mr-2"
                  >
                    <img
                      src={appleImage}
                      width="100%"
                      height="100%"
                      alt="appstore"
                    />
                  </div>
                  Apple Store
                </a>
              </li>

              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=tradefada.com.exchange.app"
                  rel="noopener noreferrer"
                >
                  <div
                    style={{
                      width: "17px",
                      height: "17px",
                      display: "inline-block",
                    }}
                    className="mr-2 mb-3"
                  >
                    <img
                      src={googleImageg}
                      width="100%"
                      height="100%"
                      alt="playstore"
                    />
                  </div>
                  Play Store
                </a>
              </li>
            </ul>
          </div>
          <div className="footermenu">
            <p className="footer-links-menu">
              {/* Community */}
              FOLLOW US
            </p>
            {/* <ul className="socialmedia-links mb-2"> */}
            <ul className="footerUI footer-link-text">
              <li className="">
                <a
                  href="https://www.facebook.com/Tradefada01"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className=" fnt-13 text-white mr-1"
                  />
                  Facebook
                </a>
              </li>
              <li className="">
                <a
                  href="https://twitter.com/tradefada"
                  rel="noopener noreferrer"
                >
                  <img
                    src={xlogo}
                    width="12px"
                    height="12px"
                    alt="x logo"
                    className=" fnt-13 text-white me-1"
                  />
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/tradefada/"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className=" fnt-13 text-white mr-1"
                  />
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://t.me/Tradefada" rel="noopener noreferrer">
                  <FontAwesomeIcon
                    icon={faTelegram}
                    className=" fnt-13 text-white mr-1"
                  />
                  Telegram
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="https://www.youtube.com/channel/UC_3gq-5fF1Yy9FTUr5W9cMg"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className=" fnt-13 text-white mr-1"
                  />
                  Youtube
                </a>
              </li>
              <li>
                {" "}
                <a
                  href="https://wa.me/message/Y7Q43TDSU427O1"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className=" fnt-13 text-white mr-1"
                  />
                  Whatsapp
                </a>
              </li>
            </ul>
            <p className="footer-link-text">support@tradefada.com</p>
            <div className=""></div>
          </div>
        </div>
        <div className="container  d-flex flex-row justify-content-start mb-4 mt-2 ">
          <div style={{ height: "45px", width: "45px" }}>
            <img src={phoneimg} alt="phoneIcon" width="100%" height="100%" />
          </div>
          <div className=" d-flex justify-content-start align-items-center bg-footer-phone ">
            {/* <p className="text-white font-demiBold">+234 906 254 7355</p> */}
            <p
              className="text-white font-demiBold "
              style={{ margin: "0px", lineHeight: "20px" }}
            >
              (+234) - 0703 186 0927 , 0807 848 2878
            </p>{" "}
          </div>
        </div>
        <div className="footer-rule "></div>
        <div className="container d-flex align-items-center py-2  text-white">
          <div className=" ">
            <img
              src={tfIcon}
              alt="footer logo"
              width="117.634"
              height="24.744"
            />
          </div>

          <div className="ml-auto  fnt-13 font-regular">
            &copy; {getCurrentYear} TradeFada Limited
          </div>
        </div>
        <NotAvailableModal show={showModal} handleClose={handleCloseModal} />
      </section>
      {showAdsection && banners.length > 0 && (
        <section>
          {" "}
          <Advert handleCloseButton={handleShowAd} banners={banners} />
        </section>
      )}
    </Fragment>
  );
};

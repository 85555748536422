import React, { Fragment } from "react";
import ModalComponent from "../../common/Modal/ModalComponent";

const WidgetModal = ({ showModal, iframeloading, handleClose }) => {
  return (
    <Fragment>
      <div className="container text-white ">
        {" "}
        <ModalComponent
          show={showModal}
          handleClose={handleClose}
          size="md"
          dialogClassName="moonpayModal"
        >
          {iframeloading ? <div className="loaderImage"></div> : null}

          <iframe
            allow="accelerometer; autoplay; camera; gyroscope; payment"
            src="https://tradefada-buy-sell-widget.azurewebsites.net/?api_key=265f050fb4ed720199a4087d1adaf896837c22d9a0155290201fafe6d571b67d"
            onLoad={!iframeloading}
            title="Tradefada"
            style={{ borderStyle: "none" }}
            className="widgetIframe"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </ModalComponent>
      </div>
    </Fragment>
  );
};

export default WidgetModal;

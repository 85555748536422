import { connect } from 'react-redux'
import { setNewsletterSubscription,clearNewsLetterResponseHandler } from "../../redux/actions/userAction";
import NewsLetterForm from "./NewsLetterForm"


const mapStateToProps = (state) => ({
    successMsg: state.user.successMsg,
    errorMsg:state.user.errors
})

const mapDispatchToProps = {
  setNewsletterSubscription,
  clearNewsLetterResponseHandler
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetterForm)
import React, { Fragment } from "react";
import Analytics from "../components/analytics/Analytics";
import { HeaderMenuButtons } from "../components/layout/header/Header";

const AnalyticPage = () => {
  return (
    <Fragment>
      <main>
        <section className="bg-white  ">
          <div className="pt-2 ">
            {" "}
            <HeaderMenuButtons />
          </div>
          <div className="container">
            {/* {" "}
            <p className="partner-header font-medium text-black">Analytics</p>
            <div className="short-rectangle my-2"></div> */}
            <div className="containingDiv">
              <Analytics />
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default AnalyticPage;

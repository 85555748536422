import React, { Fragment } from "react";
import ModalComponent from "../../../common/Modal/ModalComponent";
import ReactPlayer from "react-player";
// import isEmpty from "../../../helpers/is-empty";
// import { formatDate } from "./../../../helpers/dateHelper";
import "./VideoModal.css";

const VideoModal = ({ showModal, video, handleClose }) => {
  // const dateFormatting = (date) => {
  //   return <span>{formatDate(date, "DD-MMM-YYYY h:mm A")}</span>;
  // };
  return (
    <Fragment>
      <div className="container text-white ">
        {" "}
        <ModalComponent
          show={showModal}
          handleClose={handleClose}
          size="md"
          dialogClassName="videomodalcontent"
          // contentClassName="videomodalcontent"
        >
          {/* {iframeloading ? <div className="loaderImage"></div> : null} */}
          {video !== null && video.length > 0 && (
            <Fragment>
              <div className="player-wrapper">
                {" "}
                <ReactPlayer
                  url={
                    video !== null
                      ? `https://www.youtube.com/watch?v=${video[0].snippet.resourceId.videoId}`
                      : null
                  }
                  width="100%"
                  height="350px"
                  playing
                />
              </div>

              <div className="videomodalcontent mt-3">
                <p className="modalvideoTitle">{video[0].snippet.title}</p>
                {/* <p className="modalvideoSubTitle">
                  {dateFormatting(video[0].snippet.publishedAt)}
                </p> */}
                {/* {video[0].snippet.description} */}
              </div>
            </Fragment>
          )}
        </ModalComponent>
      </div>
    </Fragment>
  );
};

export default VideoModal;

import React from "react";
import phoneImages from "../../assets/img/award_phones.svg";
import panLogo from "../../assets/img/pan_logo.svg";
import appleImage from "../../assets/img/applebutton.png";
import playImage from "../../assets/img/playstorebutton.png";
import "./InfoSection.css";

export const InfoSection = () => {
  return (
    <div className="row">
      <div className=" col-md-6  col-xs-12 text-center text-md-left">
        <p className="pt-2  award-section-title font-bold">
          Award Winners 2021 | PAN Finance
        </p>
        <p className=" my-3 awardText font-medium">
          A verified recognizable award for industry standard, performance and
          excellence in the financial ecosystem.
        </p>
        <div>
          <a href="https://panfinance.net/winners/" rel="noopener noreferrer">
            <img src={panLogo} alt="Pan Award Logo" />
          </a>
        </div>
        <p className="award-downloadText mt-3 mb-2 font-bold">
          Download TradeFada App
        </p>
        <div className=" d-flex   justify-content-between storeImagebtnDiv ">
          <div className="d-flex align-items-center storeImagebtn">
            <img
              src={appleImage}
              alt="playstore"
              width="100%"
              height="100%"
              onClick={() =>
                window.open(
                  `https://apps.apple.com/ng/app/tradefada-global/id1540975439`,
                  "_self"
                )
              }
            />
          </div>
          <div className="d-flex align-items-center storeImagebtn ">
            <img
              src={playImage}
              alt="playstore"
              width="100%"
              height="100%"
              onClick={() =>
                window.open(
                  `https://play.google.com/store/apps/details?id=tradefada.com.exchange.app`,
                  "_self"
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="col-md-6 d-none d-sm-none  d-md-block phoneImageDiv">
        <img
          src={phoneImages}
          width="100%"
          height="100%"
          alt="phone trading images"
        />
      </div>
    </div>
  );
};

import LogRocket from 'logrocket';
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { getCryptoCurrencies, fetchExchangeTicker, } from "./actions/ratesAction";
import {getAdverts} from "./actions/adAction";
import rootReducer from "./reducers";

const initialState = {};
const middleware = [thunk];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleware, LogRocket.reduxMiddleware())
  )
);

// store.dispatch(getEthcoinRate())

store.dispatch(getCryptoCurrencies())
store.dispatch(fetchExchangeTicker());
store.dispatch(fetchExchangeTicker());
store.dispatch(getAdverts());

;


export default store;

import { SET_NEWSLETTER_SUBSCRIPTION_SUCCESS , SET_NEWSLETTER_SUBSCRIPTION_ERROR,RESET,FETCH_IP_ADDRESS} from "../actions/types";

const initialState = {
    errors: null,
    successMsg: null,
    ipAddress:null
 
};

const rateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NEWSLETTER_SUBSCRIPTION_SUCCESS:
      return { ...state, successMsg: payload };
    case SET_NEWSLETTER_SUBSCRIPTION_ERROR:
      return { ...state, errors: payload };
      case RESET:
      return { ...state, successMsg:null,errors:null };
     case FETCH_IP_ADDRESS:
      return { ...state, ipAddress: payload};
    default:
      return state;
  }
};

export default rateReducer;
import React, { useRef } from "react";
import LazyLoad from "react-lazy-load";
// import map from "../img/mapPics/map.png"
import img1 from "../img/mapPics/1.webp";
import img2 from "../img/mapPics/2.webp";
import img3 from "../img/mapPics/3.webp";
import img4 from "../img/mapPics/4.webp";
import img5 from "../img/mapPics/5.webp";
import img6 from "../img/mapPics/6.webp";
import img7 from "../img/mapPics/7.webp";
import img8 from "../img/mapPics/8.webp";
import img9 from "../img/mapPics/9.webp";
import img10 from "../img/mapPics/10.webp";
import img11 from "../img/mapPics/11.webp";
import img12 from "../img/mapPics/12.webp";
import "./MapImg.css";

export const MapImg = () => {
  const uiDiv = useRef(null);
  return (
    <div className="mapdiv" ref={uiDiv}>
      {/* <img src={map} height="100%" width="100%" alt="globalimage"/> */}

      <LazyLoad height={32} width={32}>
        <img
          src={img1}
          className="mapImages img1 radiate_blinks"
          alt="person avatar 1"
          width="100%"
          height="100px"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img12}
          className="mapImages img2"
          alt="person avatar 2"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img2}
          className="mapImages img2 flash"
          alt="person avatar 3"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img3}
          className="mapImages img3 blink3"
          alt="person avatar 4"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img4}
          className="mapImages img4 radiate_blinks"
          alt="person avatar 5"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img1}
          className="mapImages img5"
          alt="person avatar 6"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img5}
          className="mapImages img5 flash"
          alt="person avatar 7"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img6}
          className="mapImages img6 blink3"
          alt="person avatar 8"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img7}
          className="mapImages img7 blink"
          alt="person avatar 9"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img8}
          className="mapImages img8 radiate_blinks"
          alt="person avatar 10"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img9}
          className="mapImages img9 radiate_blinks"
          alt="person avatar 11"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img10}
          className="mapImages img10 blink"
          alt="person avatar 12"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img11}
          className="mapImages img11 blink"
          alt="person avatar 13"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img12}
          className="mapImages img12 radiate_blinks"
          alt="person avatar 14"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img6}
          className="mapImages img13 "
          alt="avatar 15"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img5}
          className="mapImages img13 flash"
          alt="avatar 16"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img8}
          className="mapImages img14 "
          alt="avatar 17"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img7}
          className="mapImages img14 flash"
          alt="avatar 18"
          width="100%"
          height="100%"
        />
      </LazyLoad>
      <LazyLoad height={32} width={32}>
        <img
          src={img10}
          className="mapImages img15 blink3"
          alt="avatar 19"
          width="100%"
          height="100%"
        />
      </LazyLoad>
    </div>
  );
};

/* Currency Types */
export const BTC_USD_RATE = "BTC_USD_RATE";
export const USD_NGN_RATE = "USD_NGN_RATE";

export const FETCH_TRADED_CURRENCIES = "FETCH_TRADED_CURRENCIES";
export const SET_INSTRUMENT = "SET_INSTRUMENT";
export const SET_UNO_USD_MARKET = "SET_UNO_USD_MARKET";

export const SET_CRYPTO_COINS = "SET_CRYPTO_COINS";

/* Error Handler Types */
export const SET_RATE_ERROR = "SET_RATE_ERROR";
export const ERROR_HANDLER = "ERROR_HANDLER";


export const RESET = "RESET";

// Exchange Ticker
export const FETCHING_EXCHANGE_TICKER = 'FETCHING_TICKER';
export const FETCHING_LATEST_TICKER = 'FETCHING_LATEST_TICKER';
export const FETCH_EXCHANGE_TICKER_FAILED = 'FETCH_EXCHANGE_TICKER_FAILED';
export const FETCH_LATEST_TICKER_SUCCESSFUL = 'FETCH_LATEST_TICKER_SUCCESSFUL';
export const FETCH_EXCHANGE_TICKER_SUCCESSFUL = 'FETCH_EXCHANGE_TICKER_SUCCESSFUL';
export const SET_MARKET_STATS = "SET_MARKET_STATS";
export const LOADING_MARKET_STATS = "LOADING_MARKET_STATS";


//ip Address
export const FETCH_IP_ADDRESS="FETCH_IP_ADDRESS"

//user
export const SET_NEWSLETTER_SUBSCRIPTION_SUCCESS = "SET_NEWSLETTER_SUBSCRIPTION_SUCCESS"
export const SET_NEWSLETTER_SUBSCRIPTION_ERROR = "SET_NEWSLETTER_SUBSCRIPTION_ERROR"

// transaction

export const SET_AD = "SET_AD"
export const AD_ERROR = "AD_ERROR"

//youtube videos
export const FETCH_YOUTUBE_VIDEOS = "FETCH_YOUTUBE_VIDEOS";
export const FETCHING_YOUTUBE_VIDEOS="FETCHING_YOUTUBE_VIDEOS"

